import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Grid,
  Modal,
  Paper,
  TextField,
  Typography,
  Alert,
} from "@mui/material";
import FormClient from "../components/FormClient/FormClient";
import Calculateur from "../components/Calculateur/Calculateur";
import {
  CalculateurContext,
  getFormattedData,
} from "../context/calculateurContext/calculateurContext";
import XLSX from "xlsx";
import CalculateurDevis from "../components/CalculateurDevis/CalculateurDevis";
import axios from "axios";
import { useUser } from "../context/userContext/userContext";
import { jsPDF } from "jspdf";
import { useNavigate } from "react-router-dom";
import BudgetPrevisionnel from "../components/BudgetPrevisionnel/BudgetPrevisionnel";
interface OwnProps {}

type Props = OwnProps;

const EntretienClient: FunctionComponent<Props> = (props) => {
  const [value, setValue] = React.useState<number>(0);
  const [open, setOpen] = useState(false);
  const renderElement = () => {
    switch (value) {
      case 0:
        return <FormClient />;
      case 1:
        return (
          <CalculateurContext>
            <Calculateur />
          </CalculateurContext>
        );
      case 2:
        return <CalculateurDevis />;
      case 3:
        return <Note />;
      case 4:
        return <BudgetPrevisionnel />;
      case 5:
        return <Sender />;
    }
  };

  const handleRoute = (value: number) => {
    setValue(value);
  };

  const handleModal = () => {
    setOpen(!open);
  };

  const handleReset = () => {
    localStorage.removeItem("calculateur");
    localStorage.removeItem("devisRestore");
    localStorage.removeItem("devis");
    localStorage.removeItem("form");
    localStorage.setItem("formValid", JSON.stringify({ value: false }));
    localStorage.removeItem("formRestore");
    setValue(0);
    handleModal();
  };

  return (
    <Container sx={{ paddingY: "32px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant={"h3"}>Mon 1er Rendez-vous</Typography>
        <Button variant={"outlined"} color={"warning"} onClick={handleModal}>
          Reinitialiser les formulaires
        </Button>
      </Box>
      <Box sx={{ marginX: "auto", width: "fit-content", marginY: "32px" }}>
        <ButtonGroup variant="outlined" aria-label="outlined button group">
          <Button onClick={() => handleRoute(0)}>Questionnaires</Button>
          <Button onClick={() => handleRoute(1)}>Comptes</Button>
          <Button onClick={() => handleRoute(2)}>Devis</Button>
          <Button onClick={() => handleRoute(3)}>Note</Button>
          <Button onClick={() => handleRoute(4)}>Budget Prévisionnel</Button>
          <Button onClick={() => handleRoute(5)}>Envoyer</Button>
        </ButtonGroup>
      </Box>
      <Paper elevation={3}>{renderElement()}</Paper>
      <Modal open={open} onClose={handleModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Etes vous sur de vouloir reinitialiser les formulaires
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              margin: "24px 000",
            }}
          >
            <Button
              variant={"outlined"}
              color={"primary"}
              onClick={handleModal}
            >
              Annuler
            </Button>
            <Button
              variant={"outlined"}
              color={"warning"}
              onClick={handleReset}
            >
              Confirmer
            </Button>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
};

export default EntretienClient;

const Note = () => {
  const [note, setNote] = useState("");

  function getNote() {
    const note = localStorage.getItem("note");
    if (note) {
      setNote(JSON.parse(note).value);
    }
  }
  useEffect(() => {
    getNote();
  }, []);

  function handleNoteChange(event: React.ChangeEvent<HTMLInputElement>) {
    // convert note to json
    const noteJson = JSON.stringify({ value: event.target.value });
    // save note to local storage
    localStorage.setItem("note", noteJson);
    // set note to state array note
    setNote(noteJson);
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box sx={{ flexGrow: 1 }}>
        <TextField
          id="outlined-multiline-static"
          label="Note"
          multiline
          rows={4}
          defaultValue={note}
          variant="outlined"
          onChange={handleNoteChange}
          sx={{ width: "100%" }}
        />
      </Box>
    </Box>
  );
};

const Sender = () => {
  const [dataToSend, setDataToSend] = useState<{
    form: string;
    devis: string;
    calculateur: string;
    note: string;
  }>({
    form: "",
    devis: "",
    calculateur: "",
    note: "",
  });

  const { state } = useUser();
  const [error, setError] = useState({ type: "" });
  const [downloaded, setDownloaded] = useState(false);
  const [alert, setAlert] = useState(false);

  const handleTelechargement = () => {
    if (!localStorage.getItem("form")) {
      setError({ type: "FORM_NOT_COMPLETED" });
      return;
    }
    if (!localStorage.getItem("devis")) {
      setError({ type: "DEVIS_NOT_COMPLETED" });
      return;
    }
    if (!localStorage.getItem("calculateur")) {
      setError({ type: "CALCULATEUR_NOT_COMPLETED" });
      return;
    }
    if (!localStorage.getItem("note")) {
      localStorage.setItem("note", JSON.stringify({ value: "" }));
      return;
    }

    const localFormValid = JSON.parse(localStorage.getItem("formValid") || "");
    const localForm = JSON.parse(localStorage.getItem("form") || "");
    const localCalculateur = JSON.parse(
      localStorage.getItem("calculateur") || ""
    );
    const localDevis = JSON.parse(localStorage.getItem("devis") || "");
    const localNote = localStorage.getItem("note") || "";

    if (
      !!localForm &&
      !!localCalculateur &&
      !!localDevis &&
      !!localNote &&
      localFormValid.value
    ) {
      setError({ type: "" });
      setDownloaded(true);

      const doc = new jsPDF();

      const note = JSON.parse(localNote);

      doc.text(note.value, 10, 10);
      doc.save("note.pdf");

      let formatedCalculateurData = getFormattedData(localCalculateur);
      let formatedDevisData = localDevis;
      let formatedFormData = localForm;

      /* make the worksheet */
      let wsCalculateur = XLSX.utils.json_to_sheet(formatedCalculateurData);
      let wsDevis = XLSX.utils.json_to_sheet(formatedDevisData);
      let wsForm = XLSX.utils.json_to_sheet(formatedFormData);

      /* add to workbook */
      let wbCalculateur = XLSX.utils.book_new();
      let wbDevis = XLSX.utils.book_new();
      let wbForm = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wbCalculateur, wsCalculateur, "Page 1");
      XLSX.utils.book_append_sheet(wbDevis, wsDevis, "Page 1");
      XLSX.utils.book_append_sheet(wbForm, wsForm, "Page 1");

      /* generate an XLSX file */
      XLSX.writeFile(wbCalculateur, "comptes.xlsx");
      XLSX.writeFile(wbDevis, "devis.xlsx");
      XLSX.writeFile(wbForm, "form.xlsx");
      let formBlob = XLSX.write(wbForm, { type: "array", bookType: "xlsx" });
      let calculateurBlob = XLSX.write(wbCalculateur, {
        type: "array",
        bookType: "xlsx",
      });
      let devisBlob = XLSX.write(wbDevis, { type: "array", bookType: "xlsx" });
      setDataToSend({
        form: formBlob,
        devis: devisBlob,
        calculateur: calculateurBlob,
        note: note.value,
      });
    } else if (localFormValid.value === false || !!localFormValid) {
      setError({ type: "QUESTIONNAIRE_NOT_COMPLETED" });
    }
  };

  const ErrorRender = () => {
    switch (error.type) {
      case "QUESTIONNAIRE_NOT_COMPLETED":
        return (
          <Typography align={"center"} color={"red"}>
            Vous devez remplir toutes les question obligatoire du questionnaires
          </Typography>
        );
      case "FORM_NOT_COMPLETED":
        return (
          <Typography align={"center"} color={"red"}>
            Vous devez remplir au moins une question du formulaire
          </Typography>
        );
      case "DEVIS_NOT_COMPLETED":
        return (
          <Typography align={"center"} color={"red"}>
            Vous devez remplir au moins une question du devis
          </Typography>
        );
      case "CALCULATEUR_NOT_COMPLETED":
        return (
          <Typography align={"center"} color={"red"}>
            Vous devez remplir au moins une question du calculateur
          </Typography>
        );

      default:
        return null;
    }
  };

  const handleSend = () => {
    const doc = new jsPDF();

    doc.text(dataToSend.note, 10, 10);
    var blob = doc.output("blob");

    let fd = new FormData();
    fd.append("form", new File([dataToSend.form], "form.xlsx"));
    fd.append("devis", new File([dataToSend.devis], "devis.xlsx"));
    fd.append("comptes", new File([dataToSend.calculateur], "comptes.xlsx"));
    fd.append("note", new File([blob], "note.pdf"));
    fd.append("mail", state.mail || "");
    fd.append("prenom", state.prenom || "");
    fd.append("nom", state.nom || "");

    axios
      .post("https://intranet.age-ec.fr/send-mail.php", fd)
      .then(function (response) {
        setAlert(true);
      })
      .catch(function (error) {});
  };
  const navigate = useNavigate();
  const Save = () => {
    if (!localStorage.getItem("form")) {
      setError({ type: "FORM_NOT_COMPLETED" });
      return;
    }
    if (!localStorage.getItem("devis")) {
      setError({ type: "DEVIS_NOT_COMPLETED" });
      return;
    }
    if (!localStorage.getItem("calculateur")) {
      setError({ type: "CALCULATEUR_NOT_COMPLETED" });
      return;
    }
    if (!localStorage.getItem("note")) {
      localStorage.setItem("note", JSON.stringify({ value: "" }));
      return;
    }
    const localForm = JSON.parse(localStorage.getItem("form") || "");
    const localCalculateur = JSON.parse(
      localStorage.getItem("calculateur") || ""
    );
    const localNote = JSON.parse(localStorage.getItem("note") || "");

    const localDevis = JSON.parse(localStorage.getItem("devis") || "");
    // save all data  in data
    if (!!localDevis && !!localCalculateur && !!localForm && !!localNote) {
      let base64 = btoa(
        JSON.stringify({
          form: localForm,
          calculateur: localCalculateur,
          devis: localDevis,
          note: localNote,
        })
      );

      const name = localForm[16].valeur;
      console.log(name);
      var data = JSON.stringify({
        base64: base64,
        name: name,
        mail: state.mail,
      });

      axios
        .post("https://intranet.age-ec.fr/postdata.php", data)
        .then((res) => {
          // go to clients page;
          if (res.data) {
            window.location.href = "clients";
            /*   navigate("/clients", { replace: true }); */
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <Box>
      <Grid
        container
        justifyContent={"space-around"}
        sx={{ textAlign: "center", padding: "32px" }}
      >
        <Grid item md={3}>
          <Paper
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingY: "50%",
              marginBottom: "16px",
            }}
          >
            <Typography>XLSX</Typography>
          </Paper>
          <Typography>Questionnaire</Typography>
        </Grid>
        <Grid item md={3}>
          <Paper
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingY: "50%",
              marginBottom: "16px",
            }}
          >
            <Typography>XLSX</Typography>
          </Paper>
          <Typography>Comptes</Typography>
        </Grid>
        <Grid item md={3}>
          <Paper
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingY: "50%",
              marginBottom: "16px",
            }}
          >
            <Typography>XLSX</Typography>
          </Paper>
          <Typography>Devis</Typography>
        </Grid>
        <Grid item md={3}>
          <Paper
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingY: "50%",
              marginBottom: "16px",
            }}
          >
            <Typography>PDF</Typography>
          </Paper>
          <Typography>Note</Typography>
        </Grid>
      </Grid>
      <Box>
        <ErrorRender />
      </Box>
      <Box sx={{ marginX: "auto", width: "fit-content", marginY: "32px" }}>
        <Button
          variant={"contained"}
          sx={{ marginX: "16px", width: "200px", marginBottom: "1rem" }}
          onClick={handleTelechargement}
        >
          Téléchargement
        </Button>
        <Button
          variant={"contained"}
          sx={{ marginX: "16px", width: "200px", marginBottom: "1rem" }}
          onClick={Save}
        >
          Sauvegarder
        </Button>
        {downloaded && (
          <Button
            variant={"contained"}
            sx={{ marginX: "16px", width: "200px", marginBottom: "1rem" }}
            onClick={handleSend}
          >
            Envoyer
          </Button>
          // if show_alert is true show the alert
        )}

        {alert && (
          <Alert severity="success" sx={{ margin: "16px" }}>
            Votre mail a été envoyé avec succès
          </Alert>
        )}
      </Box>
    </Box>
  );
};
