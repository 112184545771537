import {createContext, FunctionComponent, useEffect, useMemo, useReducer} from "react";
import * as React from "react";


export const userContext = createContext<{ state: State, dispatch: Dispatch } | undefined>(undefined)

export enum IUserDispatchType {
    "login",
    "initialState",
    "deconnection"
}

export type IUser = {
    mail: string | null;
    isConnected: boolean;
    nom: string;
    prenom: string;
    username: string;
}

type Action = {
    type: IUserDispatchType
} & IUser
type Dispatch = (action: Action) => void
type State = IUser

const userReducer = (state: State, action: Action): State => {
    const {type, ...payload} = action
    switch (type) {
        case IUserDispatchType.login:
            localStorage.setItem("user", JSON.stringify({...payload, isConnected: true}))
            return ({...payload, isConnected: true})
        case IUserDispatchType.deconnection:
            localStorage.setItem("user", JSON.stringify({}))
            return ({mail: null, isConnected: false, nom: "", prenom: "", username: ""})
        case IUserDispatchType.initialState:
            return ({...payload})
    }
}

const UserProvider: FunctionComponent = (props) => {
    const {children} = props
    // @ts-ignore
    const [state, dispatch] = useReducer(userReducer, {username: null, password: null, isConnected: true});

    useEffect(() => {
        let initialState = JSON.parse(localStorage.getItem("user") as string)
        if (!!initialState) {

            // @ts-ignore
            dispatch({
                type: IUserDispatchType.initialState,
                ...initialState
            })
        }
    }, []);


    const value = useMemo(() => ({
        state,
        dispatch
    }), [state])
    return (
        <userContext.Provider value={value}>
            {children}
        </userContext.Provider>
    )
}

function useUser() {
    const context = React.useContext(userContext)
    if (context === undefined) {
        throw new Error('useUser must be used within a userProvider')
    }
    return context
}

export {UserProvider, useUser}