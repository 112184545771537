import React, {FunctionComponent, useEffect, useState} from 'react';
import {TableCell, TableRow} from "@mui/material";
import {
    getMainRow,
    ICalculateurDispatchType,
    IRow,
    useCalculateur
} from "../../../context/calculateurContext/calculateurContext";

type Props = {
    rowTitle: string,
    rowId: string,
    secondRow?: IRow[],
    customSecondRowOperator?: string,
    customMainRowOperator?: string,
    color?: string,
};

const Row28: FunctionComponent<Props> = (props) => {


    const {rowTitle, rowId, color} = props
    const [totalRow, setTotalRow] = useState<number>(0);
    const {
        state,
        dispatch

    } = useCalculateur();

    useEffect(() => {

        let value1 = getMainRow(state, "9")?.value1 - getMainRow(state, "12")?.value1 || 0;
        setTotalRow(value1)
    }, [state])

    useEffect(() => {
        dispatch({type: ICalculateurDispatchType.addMainRow, mainRowId: rowId, mainRowTitle:rowTitle})
    }, [])

    useEffect(() => {
        dispatch({
            type: ICalculateurDispatchType.changeValueMainRow,
            mainRowId: rowId,
            value: totalRow,
            colonne: "value1"
        })
    }, [totalRow])

    return (
        <>
            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}, position: 'relative',backgroundColor: color}}>
                <TableCell component="th" scope="row">
                    {rowTitle}
                </TableCell>
                <TableCell align={"right"}>
                    {totalRow}
                </TableCell>
                <TableCell align={"right"}>
                </TableCell>
            </TableRow>
        </>
    )
};
export default Row28

