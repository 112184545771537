import {IRow} from "../../context/calculateurContext/calculateurContext";
import {v4 as uuidv4} from "uuid";

export const ventesMarchandises: IRow[] = [
    {secondRowId: uuidv4(), value1: 60000, value2: 30, rowTitle: "Vente 1"},
]

export const achatsEffectuesMarchandises: IRow[] = [
    {secondRowId: uuidv4(), value1: 42000, value2: 0, rowTitle: "Achat 1"},
]

export const variationStockMarchandises: IRow[] = [
    {value1: 5000, value2: 0, secondRowId: uuidv4(), rowTitle: "Stock début"},
    {value1: 5000, value2: 0, secondRowId: uuidv4(), rowTitle: "Stock fin"},
]

export const production: IRow[] = [
    {secondRowId: uuidv4(), value1: 60000, value2: 70, rowTitle: "A"},
]

export const achatsMatierePremiere: IRow[] = [
    {secondRowId: uuidv4(), value1: 18000, value2: 0, rowTitle: "A"},
]

export const variationStockMatieres: IRow[] = [
    {value1: 2000, value2: 0, secondRowId: uuidv4(), rowTitle: "Stock début"},
    {value1: 2000, value2: 0, secondRowId: uuidv4(), rowTitle: "Stock fin"},
]

export const fournituresConsommables: IRow[] = [
    {secondRowId: uuidv4(), value1: 1200, value2: 0, rowTitle: "606110 - Electricité"},
    {secondRowId: uuidv4(), value1: 600, value2: 0, rowTitle: "606120 - Eau"},
    {secondRowId: uuidv4(), value1: 2400, value2: 0, rowTitle: "606140 - Combustible"},
    {secondRowId: uuidv4(), value1: 1500, value2: 0, rowTitle: "606300 - Petit équipement"},
    {secondRowId: uuidv4(), value1: 250, value2: 0, rowTitle: "606800 - Autres fournitures"},
]

export const servicesExterieurs: IRow[] = [
    {secondRowId: uuidv4(), value1: 300, value2: 0, rowTitle: "611000 - Sous-traitance générale"},
    {secondRowId: uuidv4(), value1: 0, value2: 0, rowTitle: "612000 - Redevances crédit-bail"},
    {secondRowId: uuidv4(), value1: 6000, value2: 0, rowTitle: "613200 - Location immobilière"},
    {secondRowId: uuidv4(), value1: 600, value2: 0, rowTitle: "613500 - Location mobilière"},
    {secondRowId: uuidv4(), value1: 300, value2: 0, rowTitle: "614000 - Charges locatives"},
    {secondRowId: uuidv4(), value1: 1200, value2: 0, rowTitle: "615000 - Entretien et réparations"},
    {secondRowId: uuidv4(), value1: 1200, value2: 0, rowTitle: "616000 - Primes d'assurance"},
    {secondRowId: uuidv4(), value1: 0, value2: 0, rowTitle: "621000 - Personnel extérieur"},
    {secondRowId: uuidv4(), value1: 2400, value2: 0, rowTitle: "622600 - Honoraires comptables / juridiques"},
    {secondRowId: uuidv4(), value1: 1500, value2: 0, rowTitle: "622600 - Honoraires constitution"},
    {secondRowId: uuidv4(), value1: 1200, value2: 0, rowTitle: "623000 - Publicité, publications"},
    {secondRowId: uuidv4(), value1: 600, value2: 0, rowTitle: "625000 - Frais de déplacements"},
    {secondRowId: uuidv4(), value1: 600, value2: 0, rowTitle: "626000 - Frais télécommunications / banques"},
    {secondRowId: uuidv4(), value1: 300, value2: 0, rowTitle: "627000 - Services bancaires"},
    {secondRowId: uuidv4(), value1: 150, value2: 0, rowTitle: "628000 - Divers"},
]

export const impotsTaxes: IRow[] = [
    {value1: 450, value2: 0, secondRowId: uuidv4(), rowTitle: "C.E.T."},
    {value1: 0, value2: 0, secondRowId: uuidv4(), rowTitle: "Taxe d'apprentissage"},
    {value1: 0, value2: 0, secondRowId: uuidv4(), rowTitle: "Formation professionnelle"},
]

export const salairesBruts: IRow[] = [
    {value1: 0, value2: 0, secondRowId: uuidv4(), rowTitle: "Salarié SMIC"},
    {value1: 0, value2: 0, secondRowId: uuidv4(), rowTitle: "Salarié AUTRES"},
]

export const chargesSociales: IRow[] = [
    {value1: 0, value2: 4, secondRowId: uuidv4(), rowTitle: "Salarié SMIC"},
    {value1: 0, value2: 40, secondRowId: uuidv4(), rowTitle: "Salarié AUTRES"},
]

export const remunerationNette: IRow[] = [
    {value1: 18000, value2: 100, secondRowId: uuidv4(), rowTitle: "Appointement Gérant"},
]

export const cotisationsTNS: IRow[] = [
    {value1: 0, value2: 33, secondRowId: uuidv4(), rowTitle: "Cotisations Obligatoires"},
    {value1: 800, value2: 0, secondRowId: uuidv4(), rowTitle: "Cotisations Facultatives"},
    {value1: 300, value2: 0, secondRowId: uuidv4(), rowTitle: "CPF"},
    {value1: 0, value2: 6.80, secondRowId: uuidv4(), rowTitle: "CSG déductible"},
    {value1: 0, value2: 2.90, secondRowId: uuidv4(), rowTitle: "CSG + CRDS non déductible"},
]

export const immobilisationsCorporelles: IRow[] = [
    {value1: 20000, value2: 5, value3: 5000, secondRowId: uuidv4(), rowTitle: "Matériels & outillages"},
    {value1: 12000, value2: 3, value3: 4000, secondRowId: uuidv4(), rowTitle: "Véhicules"},
]