import React, {FunctionComponent, useEffect, useState} from 'react';
import {TableCell, TableRow, TextField} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Case from "../Case";
import {
    getMainRow,
    getSecondRows,
    ICalculateurDispatchType,
    IRow,
    useCalculateur
} from "../../../context/calculateurContext/calculateurContext";
import {RemoveCircleOutline} from "@mui/icons-material";

type Props = {
    rowTitle: string,
    rowId: string,
    secondRows?: IRow[],
    customSecondRowOperator?: string,
    customMainRowOperator?: string,
};

const Row7: FunctionComponent<Props> = (props) => {

    const {rowTitle, rowId, secondRows} = props

    const {state, dispatch} = useCalculateur();

    const [totalRow, setTotalRow] = useState<number>(0);

    useEffect(() => {
        dispatch({
            type: ICalculateurDispatchType.addMainRow, mainRowId: rowId, mainRowTitle: rowTitle, secondRows
        })
    }, [])

    useEffect(() => {
        let currentRow = getMainRow(state, rowId)
        let result = 0;
        currentRow?.rows.forEach(value => result += value.value1)
        setTotalRow(result)
    }, [state])

    useEffect(() => {
        dispatch({
            type: ICalculateurDispatchType.changeValueMainRow,
            mainRowId: rowId,
            value: totalRow,
            colonne: "value1"
        })
    }, [totalRow])

    return (
        <>
            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}, position: 'relative'}}>
                <TableCell component="th" scope="row">
                    {rowTitle}
                </TableCell>
                <TableCell align={"right"}>
                    {totalRow}
                </TableCell>
                <TableCell align={"right"}>

                </TableCell>
                <AddCircleOutlineIcon
                    onClick={() => {
                        dispatch({type: ICalculateurDispatchType.addSecondRow, mainRowId: rowId})
                    }} sx={{
                    position: 'absolute', right: -45, top: "calc(50% - 0.5em)", cursor: "pointer"
                }}/>
            </TableRow>
            {getSecondRows(state, rowId)?.map((value, index) =>
                (
                    <TableRow key={index} sx={{'&:last-child td, &:last-child th': {border: 0}, position: 'relative'}}>
                        <TableCell component="th" scope="row">
                            <TextField
                                label={"Libellé"}
                                size="small"
                                defaultValue={value.rowTitle}
                                onChange={(event) => {
                                    dispatch({
                                        type: ICalculateurDispatchType.changeTitleSecondRow,
                                        mainRowId: rowId,
                                        secondRowTitle: event.target.value,
                                        secondRowId: value.secondRowId
                                    })
                                }}
                            />
                        </TableCell>
                        <TableCell align={"right"}>
                            <Case mainRowId={rowId} secondRowId={value.secondRowId}
                                  colonne={"value1"} defaultValue={value.value1}/>
                        </TableCell>
                        <TableCell align={"right"}>

                        </TableCell>
                        <RemoveCircleOutline
                            onClick={() => {
                                dispatch({
                                    type: ICalculateurDispatchType.removeSecondRow,
                                    mainRowId: rowId,
                                    secondRowId: value.secondRowId
                                })
                            }} sx={{
                            position: 'absolute', right: -45, top: "calc(50% - 0.5em)", cursor: "pointer"
                        }}/>
                    </TableRow>
                ))}
        </>
    )
};
export default Row7;
