import React, {FunctionComponent, useEffect, useState} from 'react';
import {TableCell, TableRow} from "@mui/material";
import Case from "../Case";
import {
    getCustomeValueSecondRow,
    getMainRow, getSecondRowByIndex, getSecondRowIndex, getSecondRows,
    ICalculateurDispatchType,
    IRow,
    useCalculateur
} from "../../../context/calculateurContext/calculateurContext";

type Props = {
    rowTitle: string,
    rowId: string,
    dependentRowId: string,
    secondRows?: IRow[],
    customSecondRowOperator?: string,
    customMainRowOperator?: string,
    secondRowPrefix: string,
};

const Row2: FunctionComponent<Props> = (props) => {

    const {rowTitle, rowId, dependentRowId, secondRowPrefix, secondRows} = props
    const [totalRow, setTotalRow] = useState<number>(0);
    const {
        state, dispatch
    } = useCalculateur();

    useEffect(() => {
        let currentRow = getMainRow(state, rowId)
        let result = 0;
        currentRow?.rows.forEach(value => result += value.value1)
        setTotalRow(result)
    }, [state])

    useEffect(() => {
        dispatch({
            type: ICalculateurDispatchType.changeValueMainRow,
            mainRowId: rowId,
            value: totalRow,
            colonne: "value1"
        })
    }, [totalRow])

    useEffect(() => {
        dispatch({type: ICalculateurDispatchType.addMainRow, mainRowId: rowId, mainRowTitle: rowTitle, secondRows})
        /*
        let result = 0;

        getSecondRows(state, dependentRowId)?.forEach((value, index) => {
        console.log(value);
        console.log(getCustomeValueSecondRow(["a", "b"], [getSecondRowByIndex(state, dependentRowId, index).value1, getSecondRowByIndex(state, dependentRowId, index).value2], "return multiplication(a , percentage(b))"));
        result += getCustomeValueSecondRow(["a", "b"], [getSecondRowByIndex(state, dependentRowId, index).value1, getSecondRowByIndex(state, dependentRowId, index).value2], "return multiplication(a , percentage(b))")
        console.log(result);
        })
        setTotalRow(result)
         */
    }, [])

    return (
        <>
            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}, position: 'relative'}}>
                <TableCell component="th" scope="row">
                    {rowTitle}
                </TableCell>
                <TableCell align={"right"}>
                    {totalRow}
                </TableCell>
                <TableCell align={"right"}>

                </TableCell>
            </TableRow>
            {getSecondRows(state, dependentRowId)?.map((value, index) =>
                (
                    <TableRow key={index} sx={{'&:last-child td, &:last-child th': {border: 0}, position: 'relative'}}>
                        <TableCell component="th" scope="row">
                            {secondRowPrefix + (index + 1)}
                        </TableCell>
                        <TableCell align={"right"}>
                            <Case view
                                  mainRowId={rowId}
                                  secondRowTitle={secondRowPrefix + (index + 1)}
                                  secondRowId={getSecondRowByIndex(state, rowId, index)?.secondRowId || ""}
                                  defaultValue={getCustomeValueSecondRow(["a", "b"], [getSecondRowByIndex(state, dependentRowId, index).value1, getSecondRowByIndex(state, dependentRowId, index).value2], "return multiplication(a , percentage(b))")}
                                  colonne={"value1"}/>
                        </TableCell>
                        <TableCell align={"right"}>
                        </TableCell>
                    </TableRow>
                ))}
        </>
    )


};
export default Row2;