import * as React from "react";
import {createContext, FunctionComponent, useEffect, useMemo, useReducer} from "react";
import {v4 as uuidv4} from "uuid";

// @ts-ignore
window.multiplication = (a: number, b: number): number => {
    return a * b
}

// @ts-ignore
window.addition = (...other: number[]): number => {
    let somme = 0;
    other.forEach(value => somme += value)
    return somme
}

// @ts-ignore
window.percentage = (a: number): number => {
    return (1 - (a / 100))
}


export type IMainRow = {
    mainRowId: string;
    rowTitle: string;
    value1: number;
    value2: number;
    value3?: number;
    rows: IRow[];
}

export type IRow = {
    secondRowId: string;
    value1: number;
    value2: number;
    value3?: number;
    rowTitle: string;
}

export enum ICalculateurDispatchType {
    "addMainRow",
    "addSecondRow",
    "changeValueMainRow",
    "changeValueSecondRow",
    "changeTitleSecondRow",
    "initialState",
    "removeSecondRow"
}

type Action = { type: ICalculateurDispatchType, mainRowId: string, secondRowId?: string, colonne?: "value1" | "value2" | "value3", value?: number, secondRowTitle?: string, mainRowTitle?: string, rows?: IMainRow[], secondRows?: IRow[] }
type Dispatch = (action: Action) => void
type State = IMainRow[]

export const calculateurContext = createContext<{ state: State, dispatch: Dispatch } | undefined>(undefined)

//utils

export const getMainRow = (rows: IMainRow[], mainRowId: string) => {
    return rows.filter(value => value.mainRowId === mainRowId)[0]
}

export const getMainRowIndex = (rows: IMainRow[], mainRowId: string) => {
    let currentRow = getMainRow(rows, mainRowId);
    return rows.indexOf(currentRow)
}

export const getSecondRows = (rows: IMainRow[], mainRowId: string) => {
    return getMainRow(rows, mainRowId)?.rows
}

export const getSecondRowByIndex = (rows: IMainRow[], mainRowId: string, index: number) => {
    return getMainRow(rows, mainRowId)?.rows[index]
}

export const getSecondRowIndex = (rows: IMainRow[], mainRowId: string, secondRowId: string) => {
    let currentRow = getMainRow(rows, mainRowId);
    return currentRow.rows.findIndex(value => value.secondRowId === secondRowId)
}

export const getCustomeValueSecondRow = (strVariable: string[], values: number[], strFunction: string) => {
    // eslint-disable-next-line no-new-func
    let addition = Function(...strVariable, strFunction);
    return addition(...values)
}


const dotationIdTab = ["27", "28"]
export const getFormattedData = (rows: IMainRow[]) => {
    let formattedData: { "Designation"?: string, "EXERICE 1"?: string, "taux % marge"?: string, "Amortissement"?: string, "Montant"?: string, "Duree"?: string, }[] = []
    rows.forEach(value => {
        if (dotationIdTab.includes(value.mainRowId)) {
            formattedData.push({
                "Designation": value.rowTitle,
                "EXERICE 1": value.value1 + "",
                "taux % marge": value.value2 + "",
            })
            value.rows.forEach(value1 => {
                formattedData.push({
                    "Designation": value1.rowTitle,
                    "Montant": value1.value1 + "",
                    "Duree": value1.value2 + "",
                    "Amortissement": value1.value3 + "",
                })
            })
        } else {
            formattedData.push({
                "Designation": value.rowTitle,
                "EXERICE 1": value.value1 + "",
                "taux % marge": value.value2 + "",
            })
            value.rows.forEach(value1 => {
                formattedData.push({
                    "Designation": value1.rowTitle,
                    "EXERICE 1": value1.value1 + "",
                    "taux % marge": value1.value2 + "",
                })
            })
        }
    })
    return formattedData
}

function CalculateurReducer(state: State, action: Action) {
    const {type, mainRowId, colonne, secondRowId, value, secondRowTitle, mainRowTitle, rows, secondRows} = action
    const updateState = [...state]
    switch (type) {
        case ICalculateurDispatchType.initialState:
            return rows ? [...rows] : [...updateState]
        case ICalculateurDispatchType.addMainRow: {
            const tab = secondRows || [];
            return (
                [...updateState, {mainRowId, value1: 0, value2: 0, rows: [...tab], rowTitle: mainRowTitle}]
            )
        }
        case ICalculateurDispatchType.addSecondRow: {
            const index = getMainRowIndex(state, mainRowId)
            const rows = getSecondRows(state, mainRowId)
            if (index !== -1 && !!rows) {
                const newId = uuidv4()
                updateState[index].rows.push({
                    rowTitle: secondRowTitle || "",
                    value1: 0,
                    value2: 0,
                    secondRowId: secondRowId || newId
                })
                return (
                    [...updateState]
                )
            }
            return state
        }
        case ICalculateurDispatchType.removeSecondRow: {
            const index = getMainRowIndex(state, mainRowId)
            const rows = getSecondRows(state, mainRowId)
            if (index !== -1 && !!rows && secondRowId) {
                const secondRowIndex = getSecondRowIndex(state, mainRowId, secondRowId)
                updateState[index].rows.splice(secondRowIndex, 1)
                return (
                    [...updateState]
                )
            }
            return state
        }
        case ICalculateurDispatchType.changeValueSecondRow: {
            const mainIndex = getMainRowIndex(state, mainRowId)
            const rows = getSecondRows(state, mainRowId)
            if (mainIndex !== -1 && !!rows && secondRowId && colonne) {
                let secondIndex = getSecondRowIndex(state, mainRowId, secondRowId)
                if (secondIndex !== -1) {
                    updateState[mainIndex].rows[secondIndex][colonne] = typeof value === 'number' ? Number(value?.toFixed(3)) || 0 : value || 0
                    return (
                        [...updateState]
                    )
                }
            }
            return state
        }
        case ICalculateurDispatchType.changeTitleSecondRow: {

            const mainIndex = getMainRowIndex(state, mainRowId)
            const rows = getSecondRows(state, mainRowId)
            if (mainIndex !== -1 && !!rows && secondRowId) {
                let secondIndex = getSecondRowIndex(state, mainRowId, secondRowId)
                if (secondIndex !== -1) {
                    updateState[mainIndex].rows[secondIndex].rowTitle = secondRowTitle || ""
                    return (
                        [...updateState]
                    )
                }
            }
            return state
        }
        case ICalculateurDispatchType.changeValueMainRow: {
            const mainIndex = getMainRowIndex(state, mainRowId)
            if (mainIndex !== -1 && colonne) {
                updateState[mainIndex][colonne] = value || 0
                return (
                    [...updateState]
                )
            }
            return state
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

const CalculateurContext: FunctionComponent = (props) => {
    const {children} = props
    // @ts-ignore
    const [state, dispatch] = useReducer(CalculateurReducer, []);


    useEffect(() => {
        let initialState = JSON.parse(localStorage.getItem("calculateur") as string)
        if (!!initialState) {

            // @ts-ignore
            dispatch({
                type: ICalculateurDispatchType.initialState,
                rows: initialState
            })
        }
    }, [])

    useEffect(() => {
        localStorage.setItem("calculateur", JSON.stringify(state))
    }, [state]);

    const value = useMemo(() => ({
        state,
        dispatch
    }), [state])

    return (
        <calculateurContext.Provider value={value}>
            {children}
        </calculateurContext.Provider>
    )
}

function useCalculateur() {
    const context = React.useContext(calculateurContext)
    if (context === undefined) {
        throw new Error('useCount must be used within a CountProvider')
    }
    return context
}

export {CalculateurContext, useCalculateur}