import React, {FunctionComponent, useState} from "react";
import {
    Box,
    Collapse,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import Row1 from "./Rows/Row1";
import Row2 from "./Rows/Row2";
import Row3 from "./Rows/Row3";
import Row4 from "./Rows/Row4";
import Row5 from "./Rows/Row5";
import Row6 from "./Rows/Row6";
import Row7 from "./Rows/Row7";
import Row8 from "./Rows/Row8";
import Row9 from "./Rows/Row9";
import Row10 from "./Rows/Row10";
import Row11 from "./Rows/Row11";
import Row12 from "./Rows/Row12";
import Row13 from "./Rows/Row13";
import Row14 from "./Rows/Row14";
import Row15 from "./Rows/Row15";
import Row16 from "./Rows/Row16";
import Row17 from "./Rows/Row17";
import Row18 from "./Rows/Row18";
import Row19 from "./Rows/Row19";
import Row20 from "./Rows/Row20";
import Row21 from "./Rows/Row21";
import Row27 from "./Rows/Row27";
import Row28 from "./Rows/Row28";
import {colors} from "../../config/colors";
import {
    achatsEffectuesMarchandises,
    achatsMatierePremiere, chargesSociales,
    cotisationsTNS,
    fournituresConsommables, immobilisationsCorporelles,
    impotsTaxes, production, remunerationNette,
    salairesBruts,
    servicesExterieurs,
    variationStockMarchandises,
    variationStockMatieres,
    ventesMarchandises
} from "./configCalculateur";
import Row29 from "./Rows/Row29";
import Row30 from "./Rows/Row30";
import Row26 from "./Rows/Row26";
import Row24 from "./Rows/Row24";
import Row22 from "./Rows/Row22";
import Row23 from "./Rows/Row23";

const Calculateur: FunctionComponent = () => {
    const [openDotation, setOpenDotation] = useState(false);
    return (
        <>
            <TableContainer sx={{maxWidth: 1140, overflow: 'initial'}}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Désignation</TableCell>
                            <TableCell align="right">EXERICE 1</TableCell>
                            <TableCell align="right">taux % marge</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <Row1 secondRows={ventesMarchandises} rowId={"1"} viewId={"2"}
                              rowTitle={"Ventes de marchandises"}
                              secondRowPrefix={"Vente "} secondRowPrefixView={"Achat "}/>
                        <Row2 secondRows={achatsEffectuesMarchandises} rowId={"2"}
                              rowTitle={"Achats effectués de marchandises"} dependentRowId={"1"}
                              secondRowPrefix={"Achat "}/>

                        <Row3 rowId={"3"}
                              rowTitle={"Variation de stock de marchandises"}
                              secondRows={variationStockMarchandises}/>
                        <Row4 color={colors.secondBeige} rowId={"4"} rowTitle={"MARGE COMMERCIALE"}/>
                        <Row1 secondRows={production} rowId={"5"} viewId={"6"}
                              rowTitle={"Production"} secondRowPrefix={"Presta. "} secondRowPrefixView={"Presta. "}/>
                        <Row2 secondRows={achatsMatierePremiere} rowId={"6"} rowTitle={"Achats de matière première"}
                              dependentRowId={"5"}
                              secondRowPrefix={"Presta. "}/>
                        <Row3 rowId={"7"}
                              rowTitle={"Variation de stock de matières"}
                              secondRows={variationStockMatieres}/>
                        <Row5 color={colors.secondBeige} rowId={"8"} rowTitle={"MARGE SUR PRODUCTION"}/>
                        <Row6 color={colors.secondBeige} rowId={"9"} rowTitle={"MARGE BRUTE"}/>

                        <Row7 secondRows={fournituresConsommables} rowId={"10"} rowTitle={"Fournitures consommables"}/>
                        <Row7 secondRows={servicesExterieurs} rowId={"11"} rowTitle={"Services extérieurs"}/>
                        <Row8 color={colors.secondBeige} rowId={"12"} rowTitle={"Charges externes (Total)"}/>
                        <Row28 color={colors.mainGreen} rowId={"36"} rowTitle={"Valeur Ajoutée"}/>
                        <Row9 color={colors.secondBeige} rowId={"13"}
                              rowTitle={"Impôts et taxes"}
                              secondRows={impotsTaxes}/>
                        <Row10 rowId={"14"}
                               rowTitle={"Salaires bruts (Salariés)"}
                               secondRow={salairesBruts}/>

                        <Row11 rowId={"15"}
                               rowTitle={"Charges sociales (Salariés)"}
                               secondRows={chargesSociales}>

                        </Row11>
                        <Row12 rowId={"16"}
                               rowTitle={"Rémunération nette (Dirigeant)"}
                               secondRows={remunerationNette}>

                        </Row12>
                        <Row13 rowId={"17"}
                               rowTitle={"Cotisations TNS"}
                               secondRows={cotisationsTNS}>
                        </Row13>
                        <Row14 color={colors.secondBeige} rowId={"18"}
                               rowTitle={"Charges de personnel (Total)"}>

                        </Row14>
                        <Row29 color={colors.mainGreen} rowId={"37"}
                               rowTitle={"Excédent Brut d'Exploitation"}>

                        </Row29>
                        <Row27 rowId={"19"}
                               rowTitle={"Dotations aux amortissements"} openDotation={openDotation}
                               setOpenDotation={setOpenDotation}/>

                    </TableBody>
                </Table>
            </TableContainer>
            <Collapse in={openDotation} timeout="auto">
                <Box sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    "& > div": {width: "calc(50% - 8px)"},
                    "& th,& td": {height: "50px"}
                }}>
                    <div>
                        <TableContainer sx={{maxWidth: 1140, overflow: 'initial'}}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Désignation</TableCell>
                                        <TableCell align="right">Montant</TableCell>
                                        <TableCell align="right">Durée</TableCell>
                                        <TableCell align="right">Amortissement</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <Row30 rowTitle={"Immobilisations incorporelles"} rowId={"27"}/>
                                    <Row30 secondRows={immobilisationsCorporelles}
                                           rowTitle={"Immobilisations corporelles"} rowId={"28"}/>
                                    <Row30 rowTitle={"Immobilisations financières"} rowId={"38"}/>
                                    <TableRow/>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TableContainer sx={{maxWidth: 1140, overflow: 'initial'}}>
                            <Table>
                                <TableBody>
                                    <Row22 rowId={"29"} rowTitle={"Immobilisations (Total)"}/>
                                    <Row23 rowId={"30"}
                                           rowTitle={"Besoin de trésorerie de départ"}/>
                                    <Row23 rowId={"39"} rowTitle={"Besoin de stock de départ"}/>
                                    <Row24 rowId={"31"} rowTitle={"Total des besoins"}/>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <TableContainer sx={{maxWidth: 1140, overflow: 'initial'}}>
                        <Table sx={{
                            height: "100%",
                            "& tbody tr:last-child": {borderTop: "1px solid rgba(224, 224, 224, 1)"}
                        }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <Row7 rowId={"32"} rowTitle={"Apports en capital"}/>
                                <Row7 rowTitle={"Apports en comptes courants"} rowId={"33"}/>
                                <Row7 rowTitle={"Souscription d'emprunts"} rowId={"34"}/>
                                <TableRow/>
                                <Row26 rowId={"35"} rowTitle={"Total des ressources"}/>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Collapse>
            <TableContainer sx={{maxWidth: 1140, overflow: 'initial'}}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableBody>
                        <Row15 color={colors.mainBlue} rowId={"20"}
                               rowTitle={"Total des charges d'exploitation"}/>
                        <Row16 color={colors.mainGreen} rowId={"21"}
                               rowTitle={"Résultat d'exploitation"}>

                        </Row16>
                        <Row17 rowId={"22"}
                               rowTitle={"Charges financières"}>

                        </Row17>
                        <Row18 color={colors.mainBlue} rowId={"23"}
                               rowTitle={"Résultat financier"}>

                        </Row18>
                        <Row19 color={colors.mainGreen} rowId={"24"}
                               rowTitle={"Résultat courant"}>

                        </Row19>
                        <Row20 rowId={"25"}
                               rowTitle={"Impôt sur les bénéfices"}>

                        </Row20>
                        <Row21 color={colors.mainGreen} rowId={"26"}
                               rowTitle={"Résultat de l'exercice"}>
                        </Row21>


                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default Calculateur;

/*

 */