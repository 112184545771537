import { createRef, FunctionComponent, useEffect, useState } from "react";
import {
  Collapse,
  FormControl,
  Grid,
  GridSize,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

type Field = {
  md?: GridSize;
  label?: string;
  required?: boolean;
  type?: "select" | "area" | "repeater" | "group";
  options?: { value: string }[];
  name: string;
};

const formsFields: { section: string; fields: Field[]; idSection?: number }[] =
  [
    {
      section: "C'est quoi le projet ?",
      fields: [
        {
          label: "C'est quoi le projet ?",
          name: "projet_entite",
          required: true,
          type: "area",
        },
        {
          label: "Choix de structure juridique",
          name: "choix_structure_juridique",
          type: "area",
        },
        {
          label: "Note 1",
          name: "projet_note_1",
          type: "area",
        },
        {
          label: "Note 2",
          name: "projet_note_2",
          type: "area",
        },
      ],
    },
    {
      section: "L'entité *",
      fields: [
        {
          label: "Date d'entretien",
          name: "date_entretien",
          required: true,
        },
        {
          label: "Nom de l'entité",
          name: "nom_entite",
          required: true,
        },
        {
          label: "Secteur d'activité",
          name: "secteur_activite",
          required: true,
        },
        {
          label: "Adresse",
          name: "adresse",
          required: true,
        },
        {
          label: "Code postal",
          name: "code_postal",
          required: true,
        },
        {
          label: "Ville",
          name: "ville",
          required: true,
        },
        {
          label: "Première clôture comptable",
          name: "premiere_cloture_comptable",
        },
        {
          label: "SIRET",
          name: "siret",
        },
        {
          label: "Type de société",
          name: "type_societe",
        },
        {
          label: "Régime fiscal",
          name: "regime_fiscal",
        },
        {
          label: "Période TVA",
          name: "periode_tva",
        },
        {
          label: "Note",
          name: "section_entite_note",
          type: "area",
        },
      ],
    },
    {
      section: "Porteur de projet 1 *",
      fields: [
        { label: "Nom du porteur 1", name: "nom_porteur_1", required: true },
        {
          label: "Prenom du porteur 1",
          name: "prenom_porteur_1",
          required: true,
        },
        { label: "Mail du porteur 1", name: "mail_porteur_1", required: true },
        {
          label: "Portable du porteur 1",
          name: "portable_porteur_1",
          required: true,
        },
        {
          label: "Régime matrimonial du porteur 1",
          name: "regime_matrimonial_porteur_1",
          required: true,
        },
        {
          label: "Enfant(s) du porteur 1",
          name: "enfant_porteur_1",
          required: true,
        },
        { label: "Status du conjoint", name: "status_conjoint_1" },
        { label: "Note", name: "section_porteur_1_note", type: "area" },
      ],
    },
    {
      section: "Porteur de projet 2",
      fields: [
        { label: "Nom du porteur 2", name: "nom_porteur_2" },
        { label: "Prenom du porteur 2", name: "prenom_porteur_2" },
        { label: "Mail du porteur 2", name: "mail_porteur_2" },
        { label: "Portable du porteur 2", name: "portable_porteur_2" },
        {
          label: "Régime matrimonial du porteur 2",
          name: "regime_matrimonial_porteur_2",
        },
        { label: "Enfant(s) du porteur 2", name: "enfant_porteur_2" },
        { label: "Status du conjoint", name: "status_conjoint_2" },
        { label: "Note", name: "section_porteur_2_note", type: "area" },
      ],
    },
    {
      section: "Porteur de projet 3",
      fields: [
        { label: "Nom du porteur 3", name: "nom_porteur_3" },
        { label: "Prenom du porteur 3", name: "prenom_porteur_3" },
        { label: "Mail du porteur 3", name: "mail_porteur_3" },
        { label: "Portable du porteur 3", name: "portable_porteur_3" },
        {
          label: "Régime matrimonial du porteur 3",
          name: "regime_matrimonial_porteur_3",
        },
        { label: "Enfant(s) du porteur 3", name: "enfant_porteur_3" },
        { label: "Status du conjoint", name: "status_conjoint_3" },
        { label: "Note", name: "section_porteur_3_note", type: "area" },
      ],
    },
    {
      section: "Information complémentaire sur l'entité",
      fields: [
        {
          label: "Objectif de rémunération du dirigeant à (12/24 mois)",
          name: "objectif_remuneration_dirigeant",
        },
        {
          label: "Nombre de salarié(s)",
          name: "nombre_salarie",
        },
        {
          label: "Banque perso",
          name: "banque_perso",
        },
        {
          label: "Banque pro",
          name: "banque_pro",
        },
        {
          label: "Conseil (avocats, notaire,...)",
          name: "conseil",
        },
        {
          label: "Note",
          name: "information_complémentaire_note",
          type: "area",
        },
      ],
    },
    {
      section: "Profil du porteur",
      fields: [
        {
          label: "Que cherchez vous chez un cabinet",
          name: "cherchez_cabinet",
        },
        {
          label: "Pourquoi changer de cabinet",
          name: "changer_cabinet",
        },
        {
          label: "Remarque sur le porteur (propriétaire / locataire - projet )",
          name: "remarque_porteur",
        },
        {
          label: "Concurrence dans le métier",
          name: "concurrence_metier",
        },
        {
          label: "Risques (projet / métier)",
          name: "risques",
        },
        { label: "Note", name: "profil_porteur_note", type: "area" },
      ],
    },
    {
      section: "Recherche de besoin complémentaire",
      fields: [
        {
          label: "Plan de communication",
          name: "plan_communication",
        },
        {
          label: "Besoin informatique",
          name: "besoin_informatique",
        },
        {
          label: "Besoin en financement",
          name: "besoin_finacement",
        },
        {
          label: "Besoin en protection sociale",
          name: "besoin_protection_sociale",
        },
        { label: "Note", name: "besoin_complementaire_note", type: "area" },
      ],
    },
    {
      section: "NORME Lutte Anti-Blanchiement & financement du terrorisme *",
      fields: [
        {
          label: "Vigilence vis-à-vis de l'entité ?",
          name: "vigilence_entite",
          required: true,
        },
        {
          label: "Vigilence vis-à-vis de l'actionariat ?",
          name: "vigilence_actionariat",
          required: true,
        },
        {
          label: "Vigilence vis-à-vis des dirigeants ?",
          name: "vigilence_dirigeants",
          required: true,
        },
        {
          label: "Struture de l'entité ?",
          name: "struture_entite",
          required: true,
        },
        {
          label: "Vigilence sur les opérations ?",
          name: "vigilence_operations",
          required: true,
        },
        { label: "Note", name: "norme_note", type: "area" },
      ],
    },
    {
      section: "Pour affiner le conseil *",
      fields: [
        {
          label: "Intension de s'associé ?",
          name: "intension_associe",
          required: true,
        },
        {
          label: "Intention d'embaucher ?",
          name: "embauche",
          required: true,
        },
        {
          label: "Historique de santé ?",
          name: "sante",
          required: true,
        },
        {
          label: "Choix des logiciels ?",
          name: "choix_logiciels",
        },
        {
          label: "Irpp",
          name: "irpp",
          type: "select",
          options: [{ value: "Oui" }, { value: "Non" }],
        },
        { label: "Note", name: "conseil_note", type: "area" },
      ],
    },
    {
      section: "Informations secrétariat *",
      fields: [
        {
          label: "Entreprise à créer ou créée",
          name: "projet_entreprise",
          type: "select",
          options: [
            { value: "Entreprise à créer" },
            { value: "Entreprise déjà créée" },
          ],
        },
        {
          label: "Récupéré d'un confrère ?",
          name: "recupere_confrere",
          type: "select",
          options: [
            { value: "Récupéré d'un confrère" },
            { value: "nouveau dossier" },
          ],
        },
        {
          label: "Note 1",
          name: "information_secretariat_note",
          type: "area",
        },
      ],
    },
    {
      section: "Autres",
      fields: [
        {
          label: "Comment avez-vous connu AGE",
          name: "connu_age",
          type: "area",
          md: 4,
        },

        {
          label: "Autres info",
          type: "area",
          name: "autres_info",
          md: 4,
        },
        {
          label: "Maturité projet",
          name: "maturite_projet",
          type: "select",
          options: [{ value: "E" }, { value: "D" }, { value: "DA" }],
          md: 2,
          required: true,
        },
      ],
    },
  ];

const FormClient: FunctionComponent = () => {
  const formRef = createRef<HTMLFormElement>();

  const handleChange = () => {
    let allAreFilled = true;
    let data = new FormData(formRef.current || undefined);
    let formattedData: { libelle: string; valeur: string }[] = [];

    let formatedDataRestore: { [name: string]: string } = {};
    // @ts-ignore
    for (let key of data.keys()) {
      formatedDataRestore[key] = data.get(key) as string;
      formattedData.push({
        libelle: key,
        valeur: data.get(key) as string,
      });
    }

    formRef.current?.querySelectorAll("[required]").forEach(function (element) {
      // @ts-ignore
      if (!element.value) allAreFilled = false;
    });
    localStorage.setItem("formValid", JSON.stringify({ value: allAreFilled }));
    localStorage.setItem("formRestore", JSON.stringify(formatedDataRestore));
    localStorage.setItem("form", JSON.stringify(formattedData));
  };

  return (
    <>
      <form ref={formRef} onChange={handleChange}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Fiche d'information</TableCell>
                <TableCell align="right">(* : information requise)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formsFields.map((value, index) => (
                <RenderSectionForm key={index} value={value} index={index} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </form>
    </>
  );
};

export default FormClient;

const RenderSectionForm = (props: {
  value: { section: string; fields: Field[]; idSection?: number | undefined };
  index: number;
}) => {
  const { section, fields } = props.value;
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow
        onClick={() => setOpen(!open)}
        sx={{ backgroundColor: "#0000000A" }}
      >
        <TableCell>{section}</TableCell>
        <TableCell align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto">
            <Grid
              container
              sx={{ marginY: "16px" }}
              columnSpacing={2}
              rowSpacing={2}
            >
              {fields.map((value, index) => (
                <RenderFieldForm value={value} key={index} />
              ))}
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const RenderFieldForm = (props: { value: Field }) => {
  const { value } = props;

  const [defaultValuetrueParsed, setDefaultValuetrueParsed] =
    useState<string>("");
  const handleChange = (e: any) => {
    setDefaultValuetrueParsed(e.target.value);
  };

  useEffect(() => {
    let defaultValuetrue = localStorage.getItem("formRestore");
    if (defaultValuetrue != null) {
      setDefaultValuetrueParsed(
        JSON.parse(defaultValuetrue)[value.name] as string
      );
    }
  }, [value.name]);

  if (defaultValuetrueParsed === undefined) {
    // open localstorage formRestore and get value for this field and set it to defaultValuetrueParsed
    var formRestore: { [name: string]: string } = {};
    let defaultValuetrue = localStorage.getItem("formRestore");
    if (defaultValuetrue != null) {
      formRestore = JSON.parse(defaultValuetrue);
      for (let key in formRestore) {
  
        var formRestorekey: any = formRestore[key];
     
        if (formRestorekey.libelle === value.name) {
          setDefaultValuetrueParsed(formRestorekey.valeur);
        }
      }
    }
  }
  if (value.type === "select") {
    return (
      <Grid item md={3}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">{value.label}</InputLabel>
          <Select
            name={value.name}
            label={value.label}
            defaultValue={defaultValuetrueParsed}
          >
            {value.options &&
              value.options.map((value, index) => {
                return (
                  <MenuItem
                    key={index}
                    selected={defaultValuetrueParsed === value.value}
                    value={value.value}
                  >
                    {value.value}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  }
  if (value.type === "area") {
    return (
      <Grid item md={3}>
        <TextField
          minRows={4}
          maxRows={8}
          multiline
          fullWidth
          required={value.required}
          label={value.label}
          variant="outlined"
          name={value.name}
          value={defaultValuetrueParsed}
          onChange={handleChange}
        />
      </Grid>
    );
  }
  return (
    <Grid item md={3}>
      <TextField
        fullWidth
        required={value.required}
        label={value.label}
        variant="outlined"
        name={value.name}
        value={defaultValuetrueParsed}
        onChange={handleChange}
      />
    </Grid>
  );
};
