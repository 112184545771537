import React, {FunctionComponent, useEffect, useState} from 'react';
import {IconButton, TableCell, TableRow} from "@mui/material";
import {
    getMainRow,
    ICalculateurDispatchType,
    IRow,
    useCalculateur
} from "../../../context/calculateurContext/calculateurContext";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

type Props = {
    rowTitle: string,
    rowId: string,
    secondRow?: IRow[],
    customSecondRowOperator?: string,
    customMainRowOperator?: string,
    openDotation: boolean,
    setOpenDotation: (value: boolean) => void
};

const Row27: FunctionComponent<Props> = (props) => {


    const {rowTitle, rowId, openDotation, setOpenDotation} = props
    const [totalRow, setTotalRow] = useState<number>(0);
    const {
        state,
        dispatch

    } = useCalculateur();

    useEffect(() => {

        let value1 = getMainRow(state, "27")?.value1 + getMainRow(state, "28")?.value1 + getMainRow(state, "38")?.value1 || 0;
        setTotalRow(value1)
    }, [state])

    useEffect(() => {
        dispatch({type: ICalculateurDispatchType.addMainRow, mainRowId: rowId, mainRowTitle: rowTitle})
    }, [])

    useEffect(() => {
        dispatch({
            type: ICalculateurDispatchType.changeValueMainRow,
            mainRowId: rowId,
            value: totalRow,
            colonne: "value1"
        })
    }, [totalRow])

    return (
        <>
            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}, position: 'relative'}} onClick={() => setOpenDotation(!openDotation)}>
                <TableCell component="th" scope="row">
                    {rowTitle}
                </TableCell>
                <TableCell align={"right"}>
                    {totalRow}
                </TableCell>
                <TableCell align={"right"}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpenDotation(!openDotation)}
                    >
                        {openDotation ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
            </TableRow>
        </>
    )
};
export default Row27

