import React, {FunctionComponent, useEffect, useState} from 'react';
import {TableCell, TableRow} from "@mui/material";
import Case from "../Case";
import {
    getCustomeValueSecondRow,
    getMainRow, getSecondRowByIndex, getSecondRows,
    ICalculateurDispatchType,
    IRow,
    useCalculateur
} from "../../../context/calculateurContext/calculateurContext";

type Props = {
    rowTitle: string,
    rowId: string,
    secondRows: IRow[],
    customSecondRowOperator?: string,
    customMainRowOperator?: string,
    color?: string,
};

const Row9: FunctionComponent<Props> = (props) => {

    const {rowTitle, rowId, secondRows,color} = props
    const [totalRow, setTotalRow] = useState<number>(0);
    const {
        state,
        dispatch

    } = useCalculateur();


    useEffect(() => {
        dispatch({type: ICalculateurDispatchType.addMainRow, mainRowId: rowId, mainRowTitle:rowTitle, secondRows})
    }, [])

    useEffect(() => {
        let currentRow = getMainRow(state, rowId)
        let result = currentRow?.rows[0]?.value1 + currentRow?.rows[1]?.value1 + currentRow?.rows[2]?.value1 || 0;
        setTotalRow(result)
    }, [state])

    useEffect(() => {
        dispatch({
            type: ICalculateurDispatchType.changeValueMainRow,
            mainRowId: rowId,
            value: totalRow,
            colonne: "value1"
        })
    }, [totalRow])

    const secondRowFromState = getSecondRows(state, rowId)

    return (
        <>
            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}, position: 'relative',backgroundColor: color}}>
                <TableCell component="th" scope="row">
                    {rowTitle}
                </TableCell>
                <TableCell align={"right"}>
                    {totalRow}
                </TableCell>
                <TableCell align={"right"}>

                </TableCell>
            </TableRow>

            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}, position: 'relative'}}>
                <TableCell component="th" scope="row">
                    {secondRows && secondRows[0]?.rowTitle}
                </TableCell>
                <TableCell align={"right"}>
                    <Case mainRowId={rowId} secondRowId={secondRows && secondRows[0]?.secondRowId}
                          colonne={"value1"} defaultValue={secondRows && secondRows[0].value1}/>
                </TableCell>
                <TableCell align={"right"}>

                </TableCell>
            </TableRow>
            <TableRow
                sx={{'&:last-child td, &:last-child th': {border: 0}, position: 'relative'}}>
                <TableCell component="th" scope="row">
                    {secondRows && secondRows[1]?.rowTitle}
                </TableCell>
                <TableCell align={"right"}>
                    <Case mainRowId={rowId} view secondRowId={secondRows && secondRows[1].secondRowId}
                          defaultValue={getCustomeValueSecondRow(["a", "b"], [getMainRow(state, "14")?.value1 / 100, getSecondRowByIndex(state, rowId, 1)?.value2], "return multiplication(a , b)")}
                          colonne={"value1"} />
                </TableCell>
                <TableCell align={"right"}>
                    <Case mainRowId={rowId} secondRowId={secondRows && secondRows[1].secondRowId}
                          colonne={"value2"} defaultValue={secondRows && secondRows[1].value2}/>
                </TableCell>
            </TableRow>
            <TableRow
                sx={{'&:last-child td, &:last-child th': {border: 0}, position: 'relative'}}>
                <TableCell component="th" scope="row">
                    {secondRows && secondRows[2]?.rowTitle}
                </TableCell>
                <TableCell align={"right"}>
                    <Case mainRowId={rowId} view secondRowId={secondRows && secondRows[2].secondRowId}
                          defaultValue={getCustomeValueSecondRow(["a", "b"], [getMainRow(state, "14")?.value1 / 100, getSecondRowByIndex(state, rowId, 2)?.value2], "return multiplication(a , b)")}
                          colonne={"value1"}/>
                </TableCell>
                <TableCell align={"right"}>
                    <Case mainRowId={rowId} secondRowId={secondRows && secondRows[2].secondRowId}
                          colonne={"value2"} defaultValue={secondRows && secondRows[2].value2}/>
                </TableCell>
            </TableRow>
        </>
    )
};
export default Row9;
