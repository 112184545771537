import React, {FunctionComponent} from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import {colors} from "../../config/colors";

interface OwnProps {
}

type Props = OwnProps;

const UpButtonStyle: SxProps<Theme> = {
    position: "fixed",
    bottom: 50,
    right: 50,
    backgroundColor: colors.mainBlue,
    padding: "16px",
    color: "white",
    borderRadius: "50%",
    cursor: "pointer",
}

const UpButton: FunctionComponent<Props> = (props) => {
    return (
        <ArrowUpwardIcon onClick={() => window.scrollTo(0, 0)} sx={UpButtonStyle}/>
    );
};

export default UpButton;
