import React, {FunctionComponent, useEffect, useState} from 'react';
import {TableCell, TableRow} from "@mui/material";
import Case from "../Case";
import {
    getMainRow, getSecondRows,
    ICalculateurDispatchType,
    IRow,
    useCalculateur
} from "../../../context/calculateurContext/calculateurContext";

type Props = {
    rowTitle: string,
    rowId: string,
    secondRows?: IRow[],
    customSecondRowOperator?: string,
    customMainRowOperator?: string,
};

const Row3: FunctionComponent<Props> = (props) => {


    const {rowTitle, rowId, secondRows} = props
    const [totalRow, setTotalRow] = useState<number>(0);
    const {
        state,
        dispatch

    } = useCalculateur();


    useEffect(() => {
        dispatch({type: ICalculateurDispatchType.addMainRow, mainRowId: rowId, mainRowTitle:rowTitle, secondRows})
    }, [])

    useEffect(() => {
        let currentRow = getMainRow(state, rowId)
        let result = currentRow?.rows[0]?.value1 - currentRow?.rows[1]?.value1 || 0;
        setTotalRow(result)
    }, [state])

    useEffect(() => {
        dispatch({
            type: ICalculateurDispatchType.changeValueMainRow,
            mainRowId: rowId,
            value: totalRow,
            colonne: "value1"
        })
    }, [totalRow])

    return (
        <>
            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}, position: 'relative'}}>
                <TableCell component="th" scope="row">
                    {rowTitle}
                </TableCell>
                <TableCell align={"right"}>
                    {totalRow}
                </TableCell>
                <TableCell align={"right"}>

                </TableCell>
            </TableRow>
            {getSecondRows(state, rowId)?.map((value, index) =>
                (
                    <TableRow key={index} sx={{'&:last-child td, &:last-child th': {border: 0}, position: 'relative'}}>
                        <TableCell component="th" scope="row">
                            {value.rowTitle}
                        </TableCell>
                        <TableCell align={"right"}>
                            <Case mainRowId={rowId} secondRowId={value.secondRowId}
                                  colonne={"value1"} defaultValue={value.value1}/>
                        </TableCell>
                        <TableCell align={"right"}>
                        </TableCell>
                    </TableRow>
                ))}
        </>
    )
};
export default Row3
;
