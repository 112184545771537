import styled from "@emotion/styled";
import { Container } from "@mui/material";
import React, { FunctionComponent } from "react";
import ClientTable from "../components/ClientTable/ClientTable";

const Title = styled.h1`
  font-size: 40px;
  font-family: 'Roboto', sans-serif;
  text-align: center;
`;

const Clients: FunctionComponent = () => {
  return (
    <Container>
      <Title>Mes clients</Title>
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <ClientTable />
      </Container>
    </Container>
  );
};
export default Clients;
