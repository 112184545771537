import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { v4 as uuidv4 } from "uuid";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { RemoveCircleOutline } from "@mui/icons-material";

const defaultRowCompta = [
  {
    countTotal: 1200,
    coutUnitaire: 60,
    nombre: 20,
    libelle: "Révision",
    rowId: uuidv4(),
  },
  {
    countTotal: 1200,
    coutUnitaire: 50,
    nombre: 32,
    libelle: "Saisie",
    rowId: uuidv4(),
  },
];

const defaultRowPonctuel = [
  {
    countTotal: 1000,
    coutUnitaire: 1000,
    nombre: 1,
    libelle: "CONSTITUTION",
    rowId: uuidv4(),
  },
  {
    countTotal: 500,
    coutUnitaire: 500,
    nombre: 1,
    libelle: "PREVISIONNEL",
    rowId: uuidv4(),
  },
];

const defaultRowJuridique = [
  {
    countTotal: 350,
    coutUnitaire: 350,
    nombre: 1,
    libelle: "AGOA",
    rowId: uuidv4(),
  },
];

const CalculateurDevis: FunctionComponent = () => {
  const [rowsCompta, setRowsCompta] =
    useState<
      {
        coutUnitaire: number;
        libelle: string;
        nombre: number;
        countTotal: number;
        rowId: string;
      }[]
    >(defaultRowCompta);
  const [rowsSocial, setRowsSocial] = useState<
    {
      coutUnitaire: number;
      libelle: string;
      nombre: number;
      countTotal: number;
      rowId: string;
    }[]
  >([]);
  const [rowsJuridique, setRowsJuridique] =
    useState<
      {
        coutUnitaire: number;
        libelle: string;
        nombre: number;
        countTotal: number;
        rowId: string;
      }[]
    >(defaultRowJuridique);
  const [rowsPonctuel, setRowsPonctuel] =
    useState<
      {
        coutUnitaire: number;
        libelle: string;
        nombre: number;
        countTotal: number;
        rowId: string;
      }[]
    >(defaultRowPonctuel);

  useEffect(() => {
    const localData = localStorage.getItem("devisRestore");
    if (localData != null) {
      const parsedData = JSON.parse(localData);

      setRowsCompta(parsedData?.compta);
      setRowsSocial(parsedData?.social);
      setRowsJuridique(parsedData?.juridique);
      setRowsPonctuel(parsedData?.ponctuel);
    }
  }, []);

  const formattedData = () => {
    const saveData: {
      compta: {
        coutUnitaire: number;
        libelle: string;
        nombre: number;
        countTotal: number;
        rowId: string;
      }[];
      social: {
        coutUnitaire: number;
        libelle: string;
        nombre: number;
        countTotal: number;
        rowId: string;
      }[];
      juridique: {
        coutUnitaire: number;
        libelle: string;
        nombre: number;
        countTotal: number;
        rowId: string;
      }[];
      ponctuel: {
        coutUnitaire: number;
        libelle: string;
        nombre: number;
        countTotal: number;
        rowId: string;
      }[];
    } = {
      compta: [],
      social: [],
      juridique: [],
      ponctuel: [],
    };

    let formattedData: {
      coutUnitaire: number;
      libelle: string;
      nombre: number;
      countTotal: number;
      section: string;
    }[] = [];
    rowsCompta.forEach((value) => {
      saveData.compta.push({ ...value });
      formattedData.push({
        section: "Compta",
        coutUnitaire: value.coutUnitaire,
        libelle: value.libelle,
        nombre: value.nombre,
        countTotal: value.countTotal,
      });
    });
    // @ts-ignore
    formattedData.push({
      section: "Total section compta",
      countTotal: getTotalOf(1),
    });
    // @ts-ignore
    formattedData.push({});
    rowsSocial.forEach((value) => {
      saveData.social.push({ ...value });
      formattedData.push({
        section: "Social",
        coutUnitaire: value.coutUnitaire,
        libelle: value.libelle,
        nombre: value.nombre,
        countTotal: value.countTotal,
      });
    });
    // @ts-ignore
    formattedData.push({
      section: "Total section social",
      countTotal: getTotalOf(2),
    });
    // @ts-ignore
    formattedData.push({});
    rowsJuridique.forEach((value) => {
      saveData.juridique.push({ ...value });
      formattedData.push({
        section: "Juridique",
        coutUnitaire: value.coutUnitaire,
        libelle: value.libelle,
        nombre: value.nombre,
        countTotal: value.countTotal,
      });
    });
    // @ts-ignore
    formattedData.push({
      section: "Total section juridique",
      countTotal: getTotalOf(3),
    });
    // @ts-ignore
    formattedData.push({});
    rowsPonctuel.forEach((value) => {
      saveData.ponctuel.push({ ...value });
      formattedData.push({
        section: "Ponctuel",
        coutUnitaire: value.coutUnitaire,
        libelle: value.libelle,
        nombre: value.nombre,
        countTotal: value.countTotal,
      });
    });
    // @ts-ignore
    formattedData.push({
      section: "Total section ponctuel",
      countTotal: getTotalOf(4),
    });
    // @ts-ignore
    formattedData.push({});
    /* make the worksheet */

    // @ts-ignore
    formattedData.push({
      section: "Total global sans le ponctuel",
      countTotal: getTotalOf(1) + getTotalOf(2) + getTotalOf(3),
    });

    // @ts-ignore
    formattedData.push({
      section: "Total global avec le ponctuel",
      countTotal: getTotalOf(1) + getTotalOf(2) + getTotalOf(3) + getTotalOf(4),
    });
    localStorage.setItem("devisRestore", JSON.stringify(saveData));
    localStorage.setItem("devis", JSON.stringify(formattedData));
  };

  const getTotalOf = (type: number) => {
    let result = 0;
    switch (type) {
      case 1:
        rowsCompta.forEach((value) => (result += value.countTotal));
        return result;
      case 2:
        rowsSocial.forEach((value) => (result += value.countTotal));
        return result;
      case 3:
        rowsJuridique.forEach((value) => (result += value.countTotal));
        return result;
      case 4:
        rowsPonctuel.forEach((value) => (result += value.countTotal));
        return result;
      default:
        return result;
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    rowId: string,
    indexstr: "coutUnitaire" | "libelle" | "nombre",
    type: number
  ) => {
    switch (type) {
      case 1:
        setRowsCompta((prevState) => {
          const updateTab = [...prevState];
          let index = updateTab.findIndex((value) => value.rowId === rowId);
          // @ts-ignore
          updateTab[index][indexstr] =
            indexstr === "libelle"
              ? event.target.value
              : Number(event.target.value);
          updateTab[index].countTotal =
            updateTab[index].coutUnitaire * updateTab[index].nombre;
          return [...updateTab];
        });
        break;
      case 2:
        setRowsSocial((prevState) => {
          const updateTab = [...prevState];
          let index = updateTab.findIndex((value) => value.rowId === rowId);
          // @ts-ignore
          updateTab[index][indexstr] =
            indexstr === "libelle"
              ? event.target.value
              : Number(event.target.value);
          updateTab[index].countTotal =
            updateTab[index].coutUnitaire * updateTab[index].nombre;
          return [...updateTab];
        });
        break;
      case 3:
        setRowsJuridique((prevState) => {
          const updateTab = [...prevState];
          let index = updateTab.findIndex((value) => value.rowId === rowId);
          // @ts-ignore
          updateTab[index][indexstr] =
            indexstr === "libelle"
              ? event.target.value
              : Number(event.target.value);
          updateTab[index].countTotal =
            updateTab[index].coutUnitaire * updateTab[index].nombre;
          return [...updateTab];
        });
        break;
      case 4:
        setRowsPonctuel((prevState) => {
          const updateTab = [...prevState];
          let index = updateTab.findIndex((value) => value.rowId === rowId);
          // @ts-ignore
          updateTab[index][indexstr] =
            indexstr === "libelle"
              ? event.target.value
              : Number(event.target.value);
          updateTab[index].countTotal =
            updateTab[index].coutUnitaire * updateTab[index].nombre;
          return [...updateTab];
        });
        break;
    }
  };

  useEffect(() => {
    formattedData();
  }, [rowsPonctuel, rowsJuridique, rowsCompta, rowsSocial]);

  return (
    <>
      <TableContainer sx={{ overflow: "initial" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Expertise</TableCell>
              <TableCell>Coût unitaire (€)</TableCell>
              <TableCell>Libellé</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell>Coût total (€)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <RowColapse
              type={1}
              total={getTotalOf(1)}
              tab={rowsCompta}
              handleCaseChange={handleChange}
              sectionTitle={"Comptabilité"}
              handleAddRow={() =>
                setRowsCompta((prevState) => [
                  ...prevState,
                  {
                    countTotal: 0,
                    coutUnitaire: 0,
                    nombre: 0,
                    libelle: "",
                    rowId: uuidv4(),
                  },
                ])
              }
              handleRemoveRow={(rowId: string) =>
                setRowsCompta((prevState) => {
                  let newTab = [...prevState];
                  const index = newTab.findIndex(
                    (value) => value.rowId !== rowId
                  );
                  newTab.splice(index, 1);
                  return [...newTab];
                })
              }
            />
            <RowColapse
              type={2}
              total={getTotalOf(2)}
              tab={rowsSocial}
              handleCaseChange={handleChange}
              sectionTitle={"Social"}
              handleAddRow={() =>
                setRowsSocial((prevState) => [
                  ...prevState,
                  {
                    countTotal: 0,
                    coutUnitaire: 0,
                    nombre: 0,
                    libelle: "",
                    rowId: uuidv4(),
                  },
                ])
              }
              handleRemoveRow={(rowId: string) =>
                setRowsSocial((prevState) => {
                  prevState.filter((value) => value.rowId !== rowId);
                  return [...prevState];
                })
              }
            />
            <RowColapse
              type={3}
              total={getTotalOf(3)}
              tab={rowsJuridique}
              handleCaseChange={handleChange}
              sectionTitle={"Juridique"}
              handleAddRow={() =>
                setRowsJuridique((prevState) => [
                  ...prevState,
                  {
                    countTotal: 0,
                    coutUnitaire: 0,
                    nombre: 0,
                    libelle: "",
                    rowId: uuidv4(),
                  },
                ])
              }
              handleRemoveRow={(rowId: string) =>
                setRowsJuridique((prevState) => {
                  prevState.filter((value) => value.rowId !== rowId);
                  return [...prevState];
                })
              }
            />
            <RowColapse
              type={4}
              total={getTotalOf(4)}
              tab={rowsPonctuel}
              handleCaseChange={handleChange}
              sectionTitle={"Ponctuel"}
              handleAddRow={() =>
                setRowsPonctuel((prevState) => [
                  ...prevState,
                  {
                    countTotal: 0,
                    coutUnitaire: 0,
                    nombre: 0,
                    libelle: "",
                    rowId: uuidv4(),
                  },
                ])
              }
              handleRemoveRow={(rowId: string) =>
                setRowsPonctuel((prevState) => {
                  prevState.filter((value) => value.rowId !== rowId);
                  return [...prevState];
                })
              }
            />
            <TableRow
              sx={{ backgroundColor: "#0000000A", position: "relative" }}
            >
              <TableCell>Cout total du devis sans ponctuel</TableCell>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell>
                {getTotalOf(1) + getTotalOf(2) + getTotalOf(3)}
              </TableCell>
              <TableCell />
            </TableRow>
            <TableRow
              sx={{ backgroundColor: "#0000000A", position: "relative" }}
            >
              <TableCell>Cout total du devis avec ponctuel</TableCell>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell>
                {getTotalOf(1) + getTotalOf(2) + getTotalOf(3) + getTotalOf(4)}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CalculateurDevis;

const RowColapse = (props: {
  sectionTitle: string;
  handleAddRow: any;
  tab: any;
  handleCaseChange: any;
  total?: number;
  type: number;
  handleRemoveRow: any;
}) => {
  const [open, setOpen] = useState(false);

  const {
    sectionTitle,
    tab,
    handleAddRow,
    handleCaseChange,
    total,
    type,
    handleRemoveRow,
  } = props;

  return (
    <>
      <TableRow sx={{ backgroundColor: "#0000000A", position: "relative" }}>
        <TableCell>{sectionTitle}</TableCell>
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell>{total}</TableCell>
        <TableCell align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <AddCircleOutlineIcon
          onClick={handleAddRow}
          sx={{
            position: "absolute",
            right: -45,
            top: "calc(50% - 0.5em)",
            cursor: "pointer",
          }}
        />
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse
            in={open}
            timeout="auto"
            sx={{
              "&  .MuiCollapse-wrapperInner": {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              },
            }}
          >
            {tab.map(
              (value: {
                rowId: string;
                coutUnitaire: any;
                nombre: any;
                libelle: string;
              }) => (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    position: "relative",
                  }}
                >
                  <TableCell>
                    <Case
                      defaultValue={value.coutUnitaire}
                      rowId={value.rowId}
                      handleChange={handleCaseChange}
                      indexstr={"coutUnitaire"}
                      type={type}
                    />
                  </TableCell>
                  <TableCell>
                    <Case
                      defaultValue={value.libelle}
                      rowId={value.rowId}
                      handleChange={handleCaseChange}
                      indexstr={"libelle"}
                      type={type}
                    />
                  </TableCell>
                  <TableCell>
                    <Case
                      defaultValue={value.nombre}
                      rowId={value.rowId}
                      handleChange={handleCaseChange}
                      indexstr={"nombre"}
                      type={type}
                    />
                  </TableCell>
                  <TableCell>
                    {Number(value.coutUnitaire) * Number(value.nombre)}
                  </TableCell>
                  <RemoveCircleOutline
                    onClick={() => {
                      handleRemoveRow(value.rowId);
                    }}
                    sx={{
                      position: "absolute",
                      right: -45,
                      top: "calc(50% - 0.5em)",
                      cursor: "pointer",
                    }}
                  />
                </TableRow>
              )
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const Case: FunctionComponent<{
  rowId: string;
  handleChange: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    rowId: string,
    indexstr: "coutUnitaire" | "libelle" | "nombre",
    type: number
  ) => void;
  indexstr: "coutUnitaire" | "libelle" | "nombre";
  type: number;
  defaultValue: string | number;
}> = ({ rowId, handleChange, indexstr, type, defaultValue }) => {
  return (
    <TextField
      size="small"
      label={indexstr}
      defaultValue={defaultValue}
      onChange={(event) => handleChange(event, rowId, indexstr, type)}
    />
  );
};
