import React, { FC, FunctionComponent, useState } from "react";
import {
  Avatar,
  Box,
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

import logo from "../../assets/images/2016-Logo-A-G-E-fond-transparent.png";
import { colors } from "../../config/colors";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useUser } from "../../context/userContext/userContext";

type Props = {};

const UserProfile = () => {
  const { state } = useUser();
  return (
    <Box
      sx={{
        padding: "8px 16px",
        display: "flex",
        alignItems: "center",
        backgroundColor: colors.secondBlue,
        margin: "0 0 32px",
      }}
    >
      <Box component={"span"} sx={{ width: "56px" }}>
        <Avatar alt="Remy Sharp" src="" />
      </Box>
      <Typography flex={1}>
        {state.prenom} {state.nom}
      </Typography>
    </Box>
  );
};

const ListItemWithCollaps: FC = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ListItemButton onClick={() => setOpen(!open)}>
        <ListItemIcon></ListItemIcon>
        <ListItemText primary="Commercial" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }}>
            <Link
              style={{ color: "black", textDecoration: "none" }}
              to={"entretien-client"}
            >
              <ListItemText primary="1er rendez-vous" />
            </Link>
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }}>
            <Link
              style={{ color: "black", textDecoration: "none" }}
              to={"clients"}
            >
              <ListItemText primary="Mes clients" />
            </Link>
          </ListItemButton>
        </List>
      </Collapse>
    </>
  );
};

const SideBar: FunctionComponent<Props> = (props) => {
  return (
    <Drawer variant={"permanent"}>
      <Box
        className={"img-container"}
        sx={{ width: "200px", margin: "32px auto 64px" }}
      >
        <img
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
            objectPosition: "center",
          }}
          src={logo}
          alt=""
        />
      </Box>
      <UserProfile />
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <Link style={{ color: "black", textDecoration: "none" }} to={"/"}>
            <ListItemIcon></ListItemIcon>
            <ListItemText primary="Tableau de board" />
            </Link>
          </ListItemButton>
        </ListItem>
        <ListItemWithCollaps />
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon></ListItemIcon>
            <ListItemText primary="Repertoire" />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
};

export default SideBar;
