import React, { FunctionComponent, useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Box,
  Typography,
} from "@mui/material";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { saveAs } from "file-saver";

interface BudgetPrevisionnelItem {
  designation: string;
  annuel: number;
  trimestre: number;
  mensuel: number;
}

interface BudgetPrevisionnelData {
  [key: string]: BudgetPrevisionnelItem;
}

interface LocalStorageItem {
  rowTitle: string;
  value1: number;
  value2: number;
  rows: any[];
}

const designationMappings: { [key: string]: string[] } = {
  "MARGE BRUTE CIBLE": ["MARGE BRUTE"],
  "Fournitures énergies": ["Fournitures consommables"],
  "Autres frais de fonctionnements": ["Services extérieurs", "Impôts et taxes"],
  "CASH avant emprunt & rémunération": [""],
  "Remboursement des emprunts": [""],
  "BUDGET rémunération": [""],
  "Rémunération nette cible du dirigeant": ["Rémunération nette (Dirigeant)"],
  "Couverture complémentaire": ["Cotisations Facultatives"],
  "CASH avant fiscalité de l'entreprise": [
    "CASH avant fiscalité de l'entreprise",
  ],
};

const budgetPrevisionnelOrder = [
  "MARGE BRUTE CIBLE",
  "Fournitures énergies",
  "Autres frais de fonctionnements",
  "CASH avant emprunt & rémunération",
  "Remboursement des emprunts",
  "BUDGET rémunération",
  "Rémunération nette cible du dirigeant",
  "Couverture complémentaire",
  "Cotisations obligatoires régime social",
  "CASH avant fiscalité de l'entreprise",
];

const calculateCotisationsObligatoires = (
  remunerationNette: number,
  couvertureComplementaire: number
): number => {
  return (
    Math.ceil(
      ((remunerationNette + couvertureComplementaire) * 1.097 * 0.44) / 100
    ) * 100
  );
};

const BudgetPrevisionnel: FunctionComponent = () => {
  const [budgetPrevisionnelData, setBudgetPrevisionnelData] =
    useState<BudgetPrevisionnelData>({});
  const [entrepriseName, setEntrepriseName] = useState("");
  const [period, setPeriod] = useState("");

  useEffect(() => {
    const loadValues = async () => {
      const localCalculateur: LocalStorageItem[] = JSON.parse(
        localStorage.getItem("calculateur") || "[]"
      );

      const valueMap: { [key: string]: number } = {};
      localCalculateur.forEach((item) => {
        if (item.rowTitle && item.value1 !== undefined) {
          valueMap[item.rowTitle] = item.value1;
        }
      });

      const initialData: BudgetPrevisionnelData = {};
      for (const designation of budgetPrevisionnelOrder) {
        const rowTitles = designationMappings[designation] || [];
        let annualValue = 0;
        if (rowTitles.length === 1) {
          const rowTitle = rowTitles[0];
          if (rowTitle === "Remboursement des emprunts") {
            annualValue = 0;
          } else if (rowTitle === "Cotisations Facultatives") {
            annualValue = valueMap[rowTitle] || 800;
          } else {
            annualValue = valueMap[rowTitle] || 0;
          }
        } else {
          annualValue = rowTitles.reduce(
            (acc, rowTitle) => acc + (valueMap[rowTitle] || 0),
            0
          );
        }

        initialData[designation] = {
          designation,
          annuel: annualValue,
          trimestre: Math.floor(annualValue / 4),
          mensuel: Math.ceil(annualValue / 11 / 10) * 10,
        };
      }

      setBudgetPrevisionnelData(recalculateDependentValues(initialData));
    };

    loadValues();
  }, []);

  const recalculateDependentValues = (
    data: BudgetPrevisionnelData
  ): BudgetPrevisionnelData => {
    const updatedData = { ...data };

    const cashAvantEmprunt =
      updatedData["MARGE BRUTE CIBLE"].annuel -
      updatedData["Fournitures énergies"].annuel -
      updatedData["Autres frais de fonctionnements"].annuel;
    updatedData["CASH avant emprunt & rémunération"] = {
      ...updatedData["CASH avant emprunt & rémunération"],
      annuel: cashAvantEmprunt,
      trimestre: Math.floor(cashAvantEmprunt / 4),
      mensuel: Math.ceil(cashAvantEmprunt / 11 / 10) * 10,
    };

    const budgetRemuneration =
      cashAvantEmprunt - updatedData["Remboursement des emprunts"].annuel;
    updatedData["BUDGET rémunération"] = {
      ...updatedData["BUDGET rémunération"],
      annuel: budgetRemuneration,
      trimestre: Math.floor(budgetRemuneration / 4),
      mensuel: Math.ceil(budgetRemuneration / 11 / 10) * 10,
    };

    const cotisationsObligatoires = calculateCotisationsObligatoires(
      updatedData["Rémunération nette cible du dirigeant"].annuel,
      updatedData["Couverture complémentaire"].annuel
    );
    updatedData["Cotisations obligatoires régime social"] = {
      ...updatedData["Cotisations obligatoires régime social"],
      annuel: cotisationsObligatoires,
      trimestre: Math.floor(cotisationsObligatoires / 4),
      mensuel: Math.ceil(cotisationsObligatoires / 11 / 10) * 10,
    };

    const cashAvantFiscalite =
      budgetRemuneration -
      updatedData["Rémunération nette cible du dirigeant"].annuel -
      updatedData["Couverture complémentaire"].annuel -
      cotisationsObligatoires;
    updatedData["CASH avant fiscalité de l'entreprise"] = {
      ...updatedData["CASH avant fiscalité de l'entreprise"],
      annuel: cashAvantFiscalite,
      trimestre: Math.floor(cashAvantFiscalite / 4),
      mensuel: Math.ceil(cashAvantFiscalite / 11 / 10) * 10,
    };

    return updatedData;
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    designation: string
  ) => {
    const newValue = Number(event.target.value);
    setBudgetPrevisionnelData((prevData) => {
      let updatedData = { ...prevData };
      updatedData[designation] = {
        ...updatedData[designation],
        annuel: newValue,
        trimestre: Math.floor(newValue / 4),
        mensuel: Math.ceil(newValue / 11 / 10) * 10,
      };

      return recalculateDependentValues(updatedData);
    });
  };
  const generatePDF = async () => {
    const existingPdfBytes = await fetch("/budget-previsionnel.pdf").then(
      (res) => res.arrayBuffer()
    );
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const helveticaBoldFont = await pdfDoc.embedFont(
      StandardFonts.HelveticaBold
    );

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    const { width, height } = firstPage.getSize();
    const tableWidth = 500;
    const tableHeight = budgetPrevisionnelOrder.length * 20 + 35;
    const tableX = (width - tableWidth) / 2;
    const tableY = (height - tableHeight) / 2;

    // Ajout du nom de l'entreprise et de la période au-dessus du tableau
    firstPage.drawText(`${entrepriseName}`, {
      x: tableX,
      y: tableY + tableHeight + 40,
      size: 12,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });
    firstPage.drawText(`${period}`, {
      x: tableX,
      y: tableY + tableHeight + 20,
      size: 12,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });

    const colWidths = [170, 80, 80, 170];
    const headers = [
      "Désignation",
      "Annuel (€)",
      "Trimestre (€)",
      "Mensuel arrondi sur 11 mois (€)",
    ];

    // Dessiner l'en-tête du tableau avec un fond bleu et une marge blanche en bas
    firstPage.drawRectangle({
      x: tableX,
      y: tableY + tableHeight - 35,
      width: tableWidth,
      height: 35,
      color: rgb(0.44, 0.76, 0.73), // #70C2BB en RGB
    });

    headers.forEach((header, index) => {
      const headerWidth = colWidths[index];
      const headerX =
        tableX + colWidths.slice(0, index).reduce((a, b) => a + b, 0);

      const textWidth = helveticaBoldFont.widthOfTextAtSize(header, 8);
      const centeredX = headerX + (headerWidth - textWidth) / 2;

      firstPage.drawText(header, {
        x: centeredX,
        y: tableY + tableHeight - 20,
        size: 8,
        font: helveticaBoldFont,
        color: rgb(1, 1, 1), // Texte blanc
      });
    });

    // Dessiner la bordure extérieure du tableau
    firstPage.drawRectangle({
      x: tableX,
      y: tableY,
      width: tableWidth,
      height: tableHeight,
      borderColor: rgb(0, 0, 0),
      borderWidth: 0.7,
    });

    // Dessiner les lignes horizontales et le contenu du tableau
    budgetPrevisionnelOrder.forEach((designation, index) => {
      const item = budgetPrevisionnelData[designation];
      if (item) {
        const y = tableY + tableHeight - 50 - index * 20;

        // Dessiner une ligne horizontale
        firstPage.drawLine({
          start: { x: tableX, y: y + 15 },
          end: { x: tableX + tableWidth, y: y + 15 },
          thickness: 0.7,
          color: rgb(0.8, 0.8, 0.8), // Gris clair
        });

        const isBold = [
          "MARGE BRUTE CIBLE",
          "CASH avant emprunt & rémunération",
          "BUDGET rémunération",
          "CASH avant fiscalité de l'entreprise",
        ].includes(designation);

        firstPage.drawText(item.designation, {
          x: tableX + 5,
          y: y + 5,
          size: 8,
          font: isBold ? helveticaBoldFont : helveticaFont,
          color: rgb(0, 0, 0),
          maxWidth: colWidths[0] - 10,
        });

        const centerText = (text: string, colIndex: number) => {
          const textWidth = (
            isBold ? helveticaBoldFont : helveticaFont
          ).widthOfTextAtSize(text, 8);
          const colX =
            tableX + colWidths.slice(0, colIndex).reduce((a, b) => a + b, 0);
          return colX + (colWidths[colIndex] - textWidth) / 2;
        };

        firstPage.drawText(item.annuel.toString(), {
          x: centerText(item.annuel.toString(), 1),
          y: y + 5,
          size: 8,
          font: isBold ? helveticaBoldFont : helveticaFont,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText(item.trimestre.toString(), {
          x: centerText(item.trimestre.toString(), 2),
          y: y + 5,
          size: 8,
          font: isBold ? helveticaBoldFont : helveticaFont,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText(item.mensuel.toString(), {
          x: centerText(item.mensuel.toString(), 3),
          y: y + 5,
          size: 8,
          font: isBold ? helveticaBoldFont : helveticaFont,
          color: rgb(0, 0, 0),
        });
      }
    });

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    saveAs(blob, "budget-previsionnel-avec-tableau.pdf");
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h5" gutterBottom>
        Budget Prévisionnel
      </Typography>
      <Box sx={{ marginBottom: 2 }}>
        <TextField
          label="Nom de l'entreprise"
          value={entrepriseName}
          onChange={(e) => setEntrepriseName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Période"
          value={period}
          onChange={(e) => setPeriod(e.target.value)}
          fullWidth
          margin="normal"
        />
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold", width: "35%" }}>
                Désignation
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", width: "20%", textAlign: "right" }}
              >
                Annuel (€)
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", width: "20%", textAlign: "right" }}
              >
                Trimestre (€)
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", width: "25%", textAlign: "right" }}
              >
                Mensuel arrondi sur 11 mois (€)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {budgetPrevisionnelOrder.map((designation) => {
              const item = budgetPrevisionnelData[designation];
              return item ? (
                <TableRow key={item.designation}>
                  <TableCell>{item.designation}</TableCell>
                  <TableCell align="right">
                    {designation === "CASH avant emprunt & rémunération" ||
                    designation === "BUDGET rémunération" ||
                    designation === "CASH avant fiscalité de l'entreprise" ||
                    designation === "Cotisations obligatoires régime social" ? (
                      item.annuel
                    ) : (
                      <TextField
                        size="small"
                        type="number"
                        value={item.annuel}
                        onChange={(event) =>
                          handleInputChange(event, item.designation)
                        }
                        fullWidth
                        InputProps={{
                          sx: { "& input": { textAlign: "right" } },
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell align="right">{item.trimestre}</TableCell>
                  <TableCell align="right">{item.mensuel}</TableCell>
                </TableRow>
              ) : null;
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={generatePDF}
          sx={{
            marginTop: 2,
            padding: "10px 20px",
            fontSize: "1rem",
          }}
        >
          Télécharger le PDF avec le tableau
        </Button>
      </Box>
    </Box>
  );
};

export default BudgetPrevisionnel;
