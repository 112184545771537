import React, {FunctionComponent} from 'react';
import styled from "@emotion/styled";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import {IUserDispatchType, useUser} from "../../context/userContext/userContext";

interface OwnProps {
}

type Props = OwnProps;

const Header: FunctionComponent<Props> = (props) => {
    const {dispatch} = useUser()
    const handleDeconnection = () => {
        dispatch({
            mail: "",
            isConnected: false,
            nom: "",
            prenom: "",
            type: IUserDispatchType.deconnection,
            username: "",
        })
    }
    return (
        <HeaderS className="nav-container">
            <div className="link-container">
                <PowerSettingsNewIcon className={"clickable"} onClick={handleDeconnection}/>
            </div>
        </HeaderS>
    );
};

const HeaderS = styled.header`

  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 9;

  .link-container {
    display: flex;
    justify-content: flex-end;
    height: 100%;
    align-items: center;
    padding: 0 64px;
  }

  .clickable {
    cursor: pointer;
  }
`

export default Header;
