import React, {FunctionComponent, useEffect, useState} from 'react';
import {TableCell, TableRow} from "@mui/material";
import Case from "../Case";
import {
    getCustomeValueSecondRow,
    getMainRow, getSecondRowByIndex, getSecondRows,
    ICalculateurDispatchType,
    IRow,
    useCalculateur
} from "../../../context/calculateurContext/calculateurContext";

type Props = {
    rowTitle: string,
    rowId: string,
    secondRows: IRow[],
    customSecondRowOperator?: string,
    customMainRowOperator?: string,
};

const Row13: FunctionComponent<Props> = (props) => {

    const {rowTitle, rowId, secondRows} = props
    const [totalRow, setTotalRow] = useState<number>(0);
    const {
        state,
        dispatch

    } = useCalculateur();


    useEffect(() => {
        dispatch({type: ICalculateurDispatchType.addMainRow, mainRowId: rowId, mainRowTitle: rowTitle, secondRows})
    }, [])

    useEffect(() => {
        let currentRow = getMainRow(state, rowId)
        let somme = 0;
        console.log(currentRow?.rows);
        currentRow?.rows.forEach((row) => somme += row.value1 || 0)
        setTotalRow(somme)
    }, [state])

    useEffect(() => {
        dispatch({
            type: ICalculateurDispatchType.changeValueMainRow,
            mainRowId: rowId,
            value: totalRow,
            colonne: "value1"
        })
    }, [totalRow])

    return (
        <>
            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}, position: 'relative'}}>
                <TableCell component="th" scope="row">
                    {rowTitle}
                </TableCell>
                <TableCell align={"right"}>
                    {totalRow}
                </TableCell>
                <TableCell align={"right"}>

                </TableCell>
            </TableRow>
            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}, position: 'relative'}}>
                <TableCell component="th" scope="row">
                    {secondRows && secondRows[0].rowTitle}
                </TableCell>
                <TableCell align={"right"}>
                    <Case view mainRowId={rowId}
                          secondRowId={secondRows && secondRows[0].secondRowId}
                          defaultValue={getCustomeValueSecondRow(["a", "b"], [(getSecondRowByIndex(state, "16", 0)?.value1 * 1.042) / 100, getSecondRowByIndex(state, rowId, 0)?.value2], "return multiplication(a , b)")}
                          colonne={"value1"}/>

                </TableCell>
                <TableCell align={"right"}>
                    <Case mainRowId={rowId} secondRowId={secondRows && secondRows[0].secondRowId}
                          colonne={"value2"} defaultValue={secondRows && secondRows[0]?.value2}/>
                </TableCell>
            </TableRow>
            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}, position: 'relative'}}>
                <TableCell component="th" scope="row">
                    {secondRows && secondRows[1].rowTitle}
                </TableCell>
                <TableCell align={"right"}>
                    <Case mainRowId={rowId}
                          secondRowId={secondRows && secondRows[1].secondRowId}
                          colonne={"value1"} defaultValue={secondRows && secondRows[1]?.value1}/>

                </TableCell>
                <TableCell align={"right"}>

                </TableCell>
            </TableRow>
            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}, position: 'relative'}}>
                <TableCell component="th" scope="row">
                    {secondRows && secondRows[2].rowTitle}
                </TableCell>
                <TableCell align={"right"}>
                    <Case mainRowId={rowId}
                          secondRowId={secondRows && secondRows[2].secondRowId}
                          colonne={"value1"} defaultValue={secondRows && secondRows[2]?.value1}/>

                </TableCell>
                <TableCell align={"right"}>

                </TableCell>
            </TableRow>
            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}, position: 'relative'}}>
                <TableCell component="th" scope="row">
                    {secondRows && secondRows[3].rowTitle}
                </TableCell>
                <TableCell align={"right"}>
                    <Case mainRowId={rowId}
                          view
                          secondRowId={secondRows && secondRows[3].secondRowId}
                          defaultValue={
                              getCustomeValueSecondRow(
                                  ["a", "b", "c", "d", "e"],
                                  [
                                      getSecondRowByIndex(state, "16", 0)?.value1,
                                      getSecondRowByIndex(state, "17", 0)?.value1,
                                      getSecondRowByIndex(state, "17", 1)?.value1,
                                      getSecondRowByIndex(state, "17", 2)?.value1,
                                      getSecondRowByIndex(state, "17", 3)?.value2 / 100,
                                  ],
                                  "return multiplication(addition(a,b,c,d) , e)")}
                          colonne={"value1"}/>

                </TableCell>
                <TableCell align={"right"}>
                    <Case mainRowId={rowId} secondRowId={secondRows && secondRows[3].secondRowId}
                          colonne={"value2"} defaultValue={secondRows && secondRows[3]?.value2}/>
                </TableCell>
            </TableRow>
            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}, position: 'relative'}}>
                <TableCell component="th" scope="row">
                    {secondRows && secondRows[4].rowTitle}
                </TableCell>
                <TableCell align={"right"}>
                    <Case mainRowId={rowId} view
                          secondRowId={secondRows && secondRows[4].secondRowId}
                          defaultValue={
                              getCustomeValueSecondRow(
                                  ["a", "b", "c", "d", "e"],
                                  [
                                      getSecondRowByIndex(state, "16", 0)?.value1,
                                      getSecondRowByIndex(state, "17", 0)?.value1,
                                      getSecondRowByIndex(state, "17", 1)?.value1,
                                      getSecondRowByIndex(state, "17", 2)?.value1,
                                      getSecondRowByIndex(state, "17", 4)?.value2 / 100,
                                  ],
                                  "return multiplication(addition(a,b,c,d) , e)")}
                          colonne={"value1"}/>

                </TableCell>
                <TableCell align={"right"}>
                    <Case mainRowId={rowId} secondRowId={secondRows && secondRows[4].secondRowId}
                          colonne={"value2"} defaultValue={secondRows && secondRows[4]?.value2}/>
                </TableCell>
            </TableRow>
        </>
    )
};
export default Row13;
