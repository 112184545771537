import React, {FunctionComponent, useEffect} from 'react';
import {ICalculateurDispatchType, useCalculateur} from "../../context/calculateurContext/calculateurContext";
import {TextField} from "@mui/material";



type Props = {
    mainRowId: string,
    secondRowId: string,
    colonne: "value1" | "value2" | "value3",
    view?: boolean,
    defaultValue?: number,
    secondRowTitle?: string,
};

const Case: FunctionComponent<Props> = (props) => {
        const {defaultValue, view, mainRowId, secondRowId, colonne, secondRowTitle} = props
        const {dispatch} = useCalculateur()

        function debounce(func: any, args: any, timeout = 300) {
            let timer: any;
            clearTimeout(timer);
            timer = setTimeout(() => {
                func(args)
            }, timeout);

        }

        useEffect(() => {

            if (view && false) {
                dispatch({
                    type: ICalculateurDispatchType.addSecondRow,
                    mainRowId,
                    secondRowTitle: secondRowTitle
                })
            }
        }, [])


        useEffect(() => {
            if (mainRowId === "17") console.log(defaultValue);
            if (defaultValue || defaultValue === 0) {
                dispatch({
                    type: ICalculateurDispatchType.changeValueSecondRow,
                    mainRowId,
                    secondRowId,
                    colonne,
                    value: defaultValue
                })
            }
        }, [defaultValue])

        if (view) {
            return <>{Number(defaultValue?.toFixed(3))}</>
        }

        return (
            <TextField
                size="small"
                defaultValue={typeof defaultValue === 'number' ? Number(defaultValue?.toFixed(3)) : defaultValue}
                value={view && (typeof defaultValue === 'number' ? Number(defaultValue?.toFixed(3)) : defaultValue)}
                onChange={(e) => debounce(
                    (event: any) => {

                        let x = event.target.value ? event.target.value : 0
                        if (event.target.value === "0.") {

                        } else {
                            x = Number(x)
                        }
                        dispatch({
                            type: ICalculateurDispatchType.changeValueSecondRow,
                            mainRowId,
                            secondRowId,
                            colonne,
                            // @ts-ignore
                            value: x
                        })
                    }
                    , e)}
            />
        );
    }
;

export default Case;
