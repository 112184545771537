import React, {FunctionComponent, useEffect, useState} from 'react';
import {TableCell, TableRow} from "@mui/material";
import Case from "../Case";
import {
    getMainRow,
    ICalculateurDispatchType,
    IRow,
    useCalculateur
} from "../../../context/calculateurContext/calculateurContext";

type Props = {
    rowTitle: string,
    rowId: string,
    secondRow?: IRow[],
    customSecondRowOperator?: string,
    customMainRowOperator?: string,
    color?: string
};

const Row5: FunctionComponent<Props> = (props) => {


    const {rowTitle, rowId, color} = props
    const [value1, setValue1] = useState<number>(0);
    const [value2, setValue2] = useState<number>(0);
    const {
        state,
        dispatch

    } = useCalculateur();

    useEffect(() => {

        let value1 = getMainRow(state, "5")?.value1 - getMainRow(state, "6")?.value1 - getMainRow(state, "7")?.value1 || 0;
        let value2 = (getMainRow(state, "8")?.value1 / getMainRow(state, "5")?.value1) * 100 || 0;
        setValue1(value1)
        setValue2(value2)
    }, [state])

    useEffect(() => {
        dispatch({type: ICalculateurDispatchType.addMainRow, mainRowId: rowId, mainRowTitle:rowTitle})
    }, [])

    useEffect(() => {
        dispatch({
            type: ICalculateurDispatchType.changeValueMainRow,
            mainRowId: rowId,
            value: value1,
            colonne: "value1"
        })
        dispatch({
            type: ICalculateurDispatchType.changeValueMainRow,
            mainRowId: rowId,
            value: value2,
            colonne: "value2"
        })
    }, [value1, value2])

    return (
        <>
            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}, position: 'relative',backgroundColor: color}}>
                <TableCell component="th" scope="row">
                    {rowTitle}
                </TableCell>
                <TableCell align={"right"}>
                    {value1}
                </TableCell>
                <TableCell align={"right"}>
                    {value2}
                </TableCell>
            </TableRow>
        </>
    )
};
export default Row5

