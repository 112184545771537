import React, {FunctionComponent, useEffect, useState} from 'react';
import {TableCell, TableRow} from "@mui/material";
import Case from "../Case";
import {
    getMainRow,
    ICalculateurDispatchType,
    IRow,
    useCalculateur
} from "../../../context/calculateurContext/calculateurContext";

type Props = {
    rowTitle: string,
    rowId: string,
    secondRow?: IRow[],
    customSecondRowOperator?: string,
    customMainRowOperator?: string,
    color?: string
};

const Row4: FunctionComponent<Props> = (props) => {


    const {rowTitle, rowId, color} = props
    const [totalRow, setTotalRow] = useState<{ value1: number, value2: number }>({value1: 0, value2: 0});
    const {
        state,
        dispatch

    } = useCalculateur();

    useEffect(() => {

        let value1 = getMainRow(state, "1")?.value1 - getMainRow(state, "2")?.value1 + getMainRow(state, "3")?.value1 || 0;
        let value2 = (getMainRow(state, "4")?.value1 / getMainRow(state, "1")?.value1) * 100 || 0;
        if (value1 !== totalRow.value1 || value2 !== totalRow.value2) {
            setTotalRow({value1, value2})
        }
    }, [state])

    useEffect(() => {
        dispatch({type: ICalculateurDispatchType.addMainRow, mainRowId: rowId, mainRowTitle: rowTitle})
    }, [])

    useEffect(() => {
        dispatch({
            type: ICalculateurDispatchType.changeValueMainRow,
            mainRowId: rowId,
            value: totalRow.value1,
            colonne: "value1"
        })
        dispatch({
            type: ICalculateurDispatchType.changeValueMainRow,
            mainRowId: rowId,
            value: totalRow.value2,
            colonne: "value2"
        })
    }, [totalRow])

    return (
        <>
            <TableRow
                sx={{'&:last-child td, &:last-child th': {border: 0}, position: 'relative', backgroundColor: color}}>
                <TableCell component="th" scope="row">
                    {rowTitle}
                </TableCell>
                <TableCell align={"right"}>
                    {totalRow.value1}
                </TableCell>
                <TableCell align={"right"}>
                    {totalRow.value2}
                </TableCell>
            </TableRow>
        </>
    )
};
export default Row4
;
