import React, {FunctionComponent, useEffect, useState} from 'react';
import {TableCell, TableRow, TextField} from "@mui/material";
import Case from "../Case";
import {
    getMainRow,
    ICalculateurDispatchType,
    IRow,
    useCalculateur
} from "../../../context/calculateurContext/calculateurContext";

type Props = {
    rowTitle: string,
    rowId: string,
    secondRow?: IRow[],
    customSecondRowOperator?: string,
    customMainRowOperator?: string,
};

const Row14: FunctionComponent<Props> = (props) => {

    const {rowTitle, rowId} = props

    const {
        state,
        dispatch

    } = useCalculateur();


    useEffect(() => {
        dispatch({type: ICalculateurDispatchType.addMainRow, mainRowId: rowId, mainRowTitle: rowTitle})
        setTimeout( ()=> {
            dispatch({
                type: ICalculateurDispatchType.changeValueMainRow,
                mainRowId: rowId,
                colonne: "value1",
                value: 600
            })
        },300)
    }, [])


    return (
        <>
            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}, position: 'relative'}}>
                <TableCell component="th" scope="row">
                    {rowTitle}
                </TableCell>
                <TableCell align={"right"}>
                    <TextField
                        size="small"
                        defaultValue={600}
                        onChange={(event) => {
                            let x = Number(event.target.value) ? Number(event.target.value) : 0
                            dispatch({
                                type: ICalculateurDispatchType.changeValueMainRow,
                                mainRowId: rowId,
                                colonne: "value1",
                                value: Math.round(x * 100) / 100
                            })
                        }}
                    />
                </TableCell>
                <TableCell align={"right"}>
                </TableCell>
            </TableRow>
        </>
    )
};
export default Row14

